import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { AppSyncChatConfig } from '../types';
import { GraphQLQuery } from '@aws-amplify/api';
import { Observable } from '@apollo/client';

export const configure = (config: AppSyncChatConfig) => {
    console.log('CONFIGURING....', config);
    Amplify.configure(config);
    API.configure(config);
};

export class AppSyncHelper {
    public static subscribe(query: string, replace: string, value: string) {
        let subscription = query;

        subscription = subscription.replace(replace, value);
        return API.graphql<GraphQLQuery<Observable<object>>>(
            graphqlOperation(subscription)
        );
    }
}
