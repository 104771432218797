import { AppSyncChatConfig } from '../types';

export const appSyncChatConfig: AppSyncChatConfig = {
    aws_project_region: process.env.REACT_APP_APPSYNC_REGION_CHAT,
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION_CHAT,
    aws_appsync_authenticationType:
        process.env.REACT_APP_APPSYNC_AUTHENTICATION_TYPE_CHAT,
    aws_cognito_identity_pool_id: process.env.REACT_APP_APPSYNC_IDENTITY_POOL_ID,
    aws_cognito_region: process.env.REACT_APP_COGNITO_REGION_CHAT,
    aws_user_pools_web_client_id: process.env.REACT_APP_APPSYNC_POOLS_WEB_CLIENT_ID,
    skillId: '',
    clientName: '',
    clientId: '',
    chatType: '',
};
