import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { defaultRenderNodeOptions } from './contentfulRenderOptions';

const renderDocument = (richTextDocument?: Document, options: Options = {}) => {
    const { renderNode, ...otherOptions } = options;

    if (!richTextDocument) {
        return null;
    }

    return documentToReactComponents(richTextDocument, {
        renderNode: {
            ...defaultRenderNodeOptions,
            ...(renderNode || {}),
        },
        ...otherOptions,
    });
};

export default renderDocument;
