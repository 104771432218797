import * as React from 'react';
import { IconProps, Icon } from '@chakra-ui/react';

const HeaderMobileLogoIcon: React.FC<IconProps> = props => (
    <Icon
        width={'28px'}
        height={'28px'}
        viewBox={'0 0 28 28'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M13.998 0a14 14 0 1 1 .004 28 14 14 0 0 1-.004-28Z" fill="#FF9E18" />
        <path
            d="M22.058 19.87V5.992h-3.52l-2.336 3.762h2.94V19.87h-1.089v2.448h5.13V19.87h-1.125Z"
            fill="#fff"
        />
        <path
            d="M13.903 13.837 8.855 5.992H5.75v16.326h2.868V10.732l5.192 7.774h.093l4.443-6.587h-3.21l-1.233 1.918Z"
            fill="#fff"
        />
    </Icon>
);

export default HeaderMobileLogoIcon;
