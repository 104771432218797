import {
    Box,
    Button,
    Divider,
    Flex,
    Heading,
    HStack,
    Image,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Show,
    Spacer,
    Spinner,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Link, To, useNavigate } from 'react-router-dom';
import { AppRoutes, QueryKeys } from '../config/config';
import { account } from '../entities/session';
import { fetchAssessmentResult } from '../services/apiService';

import useErrorToast from '../hooks/useErrorToast';
import WarningRedIcon from '../assets/WarningRedIcon';
import RiskScoreDonutChart from '../components/assessment/RiskScoreDonutChart';
import SadIcon from '../assets/SadIcon';
import React, { ReactElement, useMemo } from 'react';
import HappyIcon from '../assets/HappyIcon';
import ShieldGreenSvg from '../assets/ShieldGreenSvg';
import renderDocument from '../utils/renderDocument';
import { getQueryString } from '../utils/helpers';
import Breadcrumbs from '../components/common/Breadcrumbs';
import WarningGrayIcon from '../assets/WarningGrayIcon';
import useContent from '../hooks/useContent';

// TODO: Apply to main object itself
export interface UserAccount {
    firstName: string;
    lastName: string;
    primaryEmail: AryEmail | string;
    secondaryEmails: AryEmail[];
    familyMembers: string[];
    isPrimaryUser: boolean;
}

export interface AryEmail {
    email: string;
    lastScanDate: number;
}

export type FailAreaProps = {
    guide: string;
    area: string;
};

export const AssetsList: React.FC<{
    text: string;
    icon: ReactElement;
    list?: {
        icon: ReactElement;
        guide: string;
        category?: string;
        url?: string | To;
        key?: string;
        resolved?: boolean;
    }[];
}> = ({ text, icon, list = [] }) => {
    const guideText = useBreakpointValue({ base: 'Guide', md: 'Start your Guide' });

    return (
        <Flex flexDir={{ base: 'column', md: 'row' }} w="full" mt={6}>
            <HStack
                p={4}
                bg="grayscale.400"
                borderRadius={{
                    base: '8px 8px 0 0',
                    md: '8px 0 0 8px',
                }}
                w="full"
                maxW={{ md: '342px' }}
                align="flex-start"
            >
                <HStack align="center">
                    {icon}
                    <Heading fontSize={{ base: 'lg', md: '2xl' }}>{text}</Heading>
                </HStack>
            </HStack>
            <Stack
                flexGrow={1}
                border="1px solid"
                borderColor="grayscale.550"
                px={4}
                py={6}
                borderRadius={{
                    base: '0 0 8px 8px',
                    md: '0 8px 8px 0',
                }}
                w="full"
                spacing={6}
            >
                {list.map(({ icon: itemIcon, guide, url, key, resolved }, i) => (
                    <React.Fragment key={key || `asset-list-item-${guide}`}>
                        <HStack>
                            {itemIcon}
                            <Text fontWeight={400}>{guide}</Text>
                            <Spacer />
                            {url && (
                                <Text
                                    as={Link}
                                    textDecor="underline"
                                    cursor="pointer"
                                    fontWeight={700}
                                    to={url}
                                >
                                    {guideText}
                                </Text>
                            )}
                            {resolved && <Text color="gray.300">Resolved</Text>}
                        </HStack>
                        {i !== list.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </Stack>
        </Flex>
    );
};

export const AssessmentResultPage = () => {
    const content = useContent();
    const accountInfo: UserAccount = account.use();
    const email =
        typeof accountInfo.primaryEmail === 'string'
            ? accountInfo.primaryEmail
            : accountInfo.primaryEmail.email;
    const firstName = accountInfo.firstName;
    const navigate = useNavigate();
    const errToast = useErrorToast();

    // TODO: Confirm if data will be fetched from contentful
    // const advert = {
    //     icon: '',
    //     title: 'Enjoy 3 FREE months when you sign-up today.',
    //     description:
    //         'Eliminate the hassles of having to remember your own passwords with McAfee Password Manager.',
    //     buttonText: 'Find out more',
    //     url: '',
    // };

    const { data, isLoading } = useQuery({
        queryKey: QueryKeys.assessments.result(email),
        queryFn: () => fetchAssessmentResult(email),
        enabled: !!email,
        onSuccess: data => {
            if (data?.score === undefined) {
                navigate(AppRoutes.ASSESSMENT);
            }
        },
        onError: errToast,
    });

    const compromisedAssets = useMemo(
        () =>
            (data?.assets?.compromisedAssets || [])
                ?.filter(({ resolved }) => !resolved)
                .map(asset => ({
                    icon: <WarningRedIcon fontSize="24px" />,
                    category: asset.category,
                    guide: asset.guide,
                    url: {
                        pathname: AppRoutes.ASSESSMENT_GUIDE,
                        search: getQueryString({
                            guide: asset.guide || '',
                            category: asset.category,
                        }),
                    },
                    key: `${asset.category}-${asset.guide}`,
                    resolved: false,
                })),
        [data?.assets?.compromisedAssets]
    );

    const resolvedAssets = useMemo(
        () =>
            (data?.assets?.compromisedAssets || [])
                ?.filter(({ resolved }) => !!resolved)
                .map(asset => ({
                    icon: <WarningGrayIcon fontSize="24px" />,
                    category: asset.category,
                    guide: asset.guide || '',
                    url: '',
                    key: `${asset.category}-${asset.guide}`,
                    resolved: true,
                })),
        [data?.assets?.compromisedAssets]
    );

    const securedAssets = useMemo(
        () =>
            (data?.assets?.securedAssets || [])?.map(({ category, guide }) => ({
                icon: <ShieldGreenSvg fontSize="24px" />,
                category: category,
                guide: guide,
                key: `${category}-${guide}`,
            })),
        [data?.assets?.securedAssets]
    );

    const risk = data?.risk || 'HIGH';
    const recommendation = data?.recommendation;

    if (isLoading) {
        return (
            <Flex align="center" justify="center" w="full" h="100vh">
                <Spinner />
            </Flex>
        );
    }

    return (
        <Flex flexDir="column" w="full" id={'assessment-result-page'}>
            <Show below="md">
                <Box px={4} pt={3}>
                    <Breadcrumbs
                        items={[
                            {
                                label: 'Dashboard',
                                href: AppRoutes.DASHBOARD,
                                current: false,
                            },
                            {
                                label: 'Security Assessment',
                                href: AppRoutes.ASSESSMENT_RESULT,
                                current: true,
                            },
                        ]}
                    />
                </Box>
            </Show>
            <Flex flexDir="column" px={{ base: 4, md: 6 }} py={6} w="full">
                <Show below="md">
                    {risk === 'HIGH' && <WarningRedIcon fontSize="2xl" mb={2} />}
                </Show>
                <Heading fontSize={{ base: '3xl', md: '4xl' }} lineHeight={10}>
                    {content(
                        `assessment.result.welcome.${risk.toLowerCase()}`,
                        '{{name}}',
                        (m, i) => (
                            <span key={i}>{firstName}</span>
                        )
                    )}
                </Heading>
                <HStack
                    spacing={2}
                    align="center"
                    fontSize={{ base: 'lg', md: '2xl' }}
                    mt={1}
                >
                    <Show above="md">{risk === 'HIGH' && <WarningRedIcon />}</Show>
                    <Text lineHeight={6}>
                        {content('assessment.result.recommendation')}
                    </Text>
                </HStack>

                <Stack
                    p={6}
                    border="solid 1px"
                    borderColor={`${risk.toLowerCase()}Risk.500`}
                    bg={`${risk.toLowerCase()}Risk.100`}
                    mt={{ base: 4, md: 6 }}
                    borderRadius="8px"
                >
                    <Flex flexDir={{ base: 'column', md: 'row' }}>
                        <RiskScoreDonutChart
                            risk={risk}
                            score={data?.score}
                            minW="120px"
                            boxSize={{ base: '140px', md: '120px' }}
                            alignSelf={{
                                base: 'center',
                                md: 'auto',
                            }}
                        />
                        <Stack
                            spacing={1}
                            mt={{ base: 4, md: 0 }}
                            ml={{ base: 0, md: 4 }}
                            flexGrow={1}
                            alignSelf="center"
                            minW={{ md: '300px' }}
                        >
                            <Text
                                textTransform="uppercase"
                                color="grayscale.200"
                                fontWeight={700}
                                fontSize="sm"
                                letterSpacing="wider"
                            >
                                {content('widgets.assessment.toprecommendation')}
                            </Text>
                            <Flex align="center" gap={2}>
                                <Heading fontSize="2xl">{recommendation?.title}</Heading>
                                {recommendation?.content && (
                                    <Popover>
                                        <PopoverTrigger>
                                            <Text
                                                textDecor="underline"
                                                cursor="pointer  "
                                            >
                                                {content('widgets.assessment.why')}
                                            </Text>
                                        </PopoverTrigger>
                                        <PopoverContent p={4}>
                                            <PopoverArrow />
                                            <PopoverBody>
                                                {renderDocument(recommendation.content)}
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                )}
                            </Flex>
                            {renderDocument(recommendation?.securityTip)}
                        </Stack>
                        <Flex
                            maxW="342px"
                            pl={{ base: 0, md: 4 }}
                            pt={{ base: 4, md: 0 }}
                            align="center"
                            justify="center"
                            w="full"
                            alignSelf="center"
                        >
                            <Button
                                w="full"
                                as={Link}
                                to={{
                                    pathname: AppRoutes.ASSESSMENT_GUIDE,
                                    search: getQueryString({
                                        guide: recommendation?.title,
                                        category: recommendation?.category,
                                    }),
                                }}
                            >
                                {content('widgets.assessment.startyourguide')}
                            </Button>
                        </Flex>
                    </Flex>

                    {/* {advert && (
                        <>
                            <Spacer mt={6} />
                            <Divider borderStyle="dashed" />
                            <Spacer mt={6} />
                        </>
                    )} */}
                    {/* {advert && (
                        <Flex
                            flexDir={{ base: 'column', md: 'row' }}
                            align="center"
                            bg="white"
                            p={4}
                            borderRadius="8px"
                            gap={4}
                            mt={6}
                        >
                            {advert.icon && (
                                <Image
                                    src={advert.icon}
                                    borderRadius={2}
                                    boxSize="80px"
                                    mb={{ base: 2, md: 0 }}
                                    minWidth="80px"
                                />
                            )}
                            {(advert.title || advert.description) && (
                                <Stack spacing={2} flexGrow={1}>
                                    <Text
                                        textAlign={{ base: 'center', md: 'left' }}
                                        fontWeight={700}
                                    >
                                        {advert.title}
                                    </Text>
                                    <Text textAlign={{ base: 'center', md: 'left' }}>
                                        {advert.description}
                                    </Text>
                                </Stack>
                            )}
                            <Box>
                                <Button
                                    as="a"
                                    variant="outline"
                                    colorScheme="gray"
                                    href={advert.url}
                                >
                                    {advert.buttonText}
                                </Button>
                            </Box>
                        </Flex>
                    )} */}
                </Stack>

                {!!compromisedAssets?.length && (
                    <AssetsList
                        text="Let's work on"
                        icon={<SadIcon boxSize="32px" />}
                        list={compromisedAssets}
                    />
                )}

                {!!(securedAssets?.length || resolvedAssets?.length) && (
                    <AssetsList
                        text="What you rock at"
                        icon={<HappyIcon boxSize="32px" />}
                        list={[...resolvedAssets, ...securedAssets]}
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default AssessmentResultPage;
