import { Button, ButtonProps, Checkbox, Divider, Flex, Stack } from '@chakra-ui/react';
import {
    WizardCardProgress,
    WizardCardStep,
    WizardCardTop,
    WizardCardTopHeading,
} from '../../common/Wizard/WizardCardTop';
import { WizardCard } from '../../common/Wizard/WizardCard';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { GuideStep } from '../../../config/interface';
import renderDocument from '../../../utils/renderDocument';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../config/config';
import {
    WizardCardBottom,
    WizardCardPolicy,
    WizardCardStepperActions,
} from '../../common/Wizard/WizardCardBottom';
import { BLOCKS } from '@contentful/rich-text-types';

interface Props {
    data: GuideStep;
    prevButtonProps: ButtonProps;
    nextButtonProps: ButtonProps;
    progress: number;
    step: number;
    numSteps: number;
    onSubmit: () => void;
}

export function GuideStepWizardCard({
    data,
    prevButtonProps,
    nextButtonProps,
    progress,
    step,
    numSteps,
    onSubmit,
}: Partial<Props>) {
    const { stepTitle, stepDescription, proTipDescription } = data || {};
    const [resolved, setResolved] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    return (
        <WizardCard>
            <WizardCardTop>
                <WizardCardTopHeading>{stepTitle}</WizardCardTopHeading>
                {step && (
                    <WizardCardStep fontWeight="500">
                        <b>Step {step} of</b> {numSteps}
                    </WizardCardStep>
                )}
                <WizardCardProgress value={progress} />
            </WizardCardTop>
            <WizardCardBottom>
                {renderDocument(stepDescription, {
                    renderNode: {
                        [BLOCKS.DOCUMENT]: (node, children) => (
                            <Flex flexDir="column" gap={1.5}>
                                {children}
                            </Flex>
                        ),
                    },
                })}
                {proTipDescription && (
                    <Stack
                        bg="grayscale.400"
                        borderRadius="8px"
                        mt={4}
                        p={4}
                        textAlign="center"
                    >
                        {renderDocument(proTipDescription)}
                    </Stack>
                )}
                {!nextButtonProps?.disabled ? (
                    <>
                        <WizardCardStepperActions
                            prevButtonProps={prevButtonProps}
                            nextButtonProps={nextButtonProps}
                        />
                        <WizardCardPolicy />
                    </>
                ) : (
                    <>
                        <Stack spacing={6} mt={4} align="center">
                            <Divider />
                            <Checkbox
                                fontWeight={700}
                                size={{ base: 'md', md: 'lg' }}
                                onChange={async e => {
                                    setLoading(true);
                                    setResolved(e.target.checked);
                                    setClicked(true);
                                    if (onSubmit) {
                                        await onSubmit();
                                    }
                                    setLoading(false);
                                }}
                                isChecked={resolved}
                            >
                                Mark as resolved
                            </Checkbox>
                            {clicked && (
                                <Button
                                    variant="outline"
                                    colorScheme="gray"
                                    leftIcon={<ArrowBackIcon />}
                                    onClick={() => {
                                        navigate(AppRoutes.ASSESSMENT_RESULT);
                                    }}
                                    disabled={!resolved}
                                    isLoading={loading}
                                >
                                    Back to Results
                                </Button>
                            )}
                        </Stack>
                        <WizardCardPolicy textAlign="center" mt={4} />
                    </>
                )}
            </WizardCardBottom>
        </WizardCard>
    );
}
