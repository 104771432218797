import * as React from 'react';

const MaskedUnmaskedSvg = props => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.025 12c1.398 2.193 3.592 4.2 6.975 4.2 3.384 0 5.577-2.007 6.975-4.2C17.577 9.807 15.384 7.8 12 7.8c-3.383 0-5.577 2.007-6.975 4.2Zm-1.22-.309C5.3 9.201 7.863 6.601 12 6.601s6.7 2.6 8.195 5.09l.185.31-.185.308C18.7 14.799 16.138 17.4 12 17.4s-6.7-2.6-8.194-5.091L3.62 12l.186-.309ZM12 9.024a2.976 2.976 0 1 0 0 5.952 2.976 2.976 0 0 0 0-5.952Z"
            fill="#333F48"
        />
        <path stroke="#333F48" d="M18.593 5.153 5.154 18.593" />
    </svg>
);

export default MaskedUnmaskedSvg;
