import * as React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Stack,
    Text,
    FormErrorMessage,
    HStack,
    Box,
} from '@chakra-ui/react';
import SmartScanLogo from '../../assets/logos/SmartScanLogo';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { NullableVar } from '../../config/interface';
import { isFeatureEnabled, isValidEmail } from '../../utils/helpers';
import RouteLink from '../common/RouteLink';
import { AppFeatures, AppRoutes } from '../../config/config';
import useContent from '../../hooks/useContent';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export type LoginProps = {
    isAuthenticating?: NullableVar<boolean>;
    onSubmit?: (email: string) => any;
};

export type LoginFormValues = {
    email: string;
};

export const Login = ({ onSubmit, isAuthenticating = false }: LoginProps) => {
    const content = useContent();
    const navigate = useNavigate();
    const [toRegister, setToRegister] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email(content('fields.email.errorMessages.email') as string)
            .required(content('fields.email.errorMessages.required') as string),
    });

    const submitHandler = ({ email }: LoginFormValues) => {
        if (onSubmit && isValidEmail(email)) {
            onSubmit(email);
        }
    };

    useEffect(() => {
        if (toRegister) {
            navigate(AppRoutes.VERIFY_MOBILE);
        }
    }, [toRegister, navigate]);

    return (
        <Card w={{ base: '343px', md: '464px' }} m={'0 auto'}>
            <CardHeader bgGradient="linear(91.62deg, grayscale.400, grayscale.600)">
                <HStack alignItems={'center'}>
                    <Text fontSize={24} fontWeight={700} marginRight={'7px'}>
                        {content('forms.login.header.welcome')}
                    </Text>
                    <SmartScanLogo props={{ style: { margin: 0 } }} />
                </HStack>
                <Text fontSize="1em">{content('forms.login.header.content')}</Text>
            </CardHeader>
            <CardBody>
                {/* {responseMessage && (
                    <BoxMessage>
                        <Text>{responseMessage}</Text>
                    </BoxMessage>
                )} */}
                <Formik
                    initialValues={
                        {
                            email: '',
                        } as LoginFormValues
                    }
                    validationSchema={LoginSchema}
                    onSubmit={submitHandler}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Stack spacing={4}>
                                <Field name="email">
                                    {({ field, form }) => {
                                        return (
                                            <FormControl
                                                isInvalid={
                                                    form.errors.email &&
                                                    form.touched.email &&
                                                    isValidEmail(form.values.email)
                                                }
                                            >
                                                <FormLabel>
                                                    {content(
                                                        'forms.login.fields.email.label'
                                                    )}
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    placeholder={content(
                                                        'forms.login.fields.email.placeholder'
                                                    )}
                                                />
                                                <FormErrorMessage>
                                                    {form.errors.email}
                                                </FormErrorMessage>
                                                <FormHelperText>
                                                    {content(
                                                        'forms.login.fields.email.helper',
                                                        'Terms of Use and Privacy Policy',
                                                        (match, i) => (
                                                            <span key={i}>
                                                                <RouteLink
                                                                    to={AppRoutes.LOGIN}
                                                                    textDecor="underline"
                                                                >
                                                                    Terms of Use
                                                                </RouteLink>
                                                                <RouteLink
                                                                    textDecor="underline"
                                                                    to={AppRoutes.LOGIN}
                                                                >
                                                                    Privacy Policy
                                                                </RouteLink>
                                                            </span>
                                                        )
                                                    )}
                                                </FormHelperText>
                                            </FormControl>
                                        );
                                    }}
                                </Field>
                                <Button
                                    isLoading={isAuthenticating || false}
                                    type="submit"
                                >
                                    {content('forms.login.fields.submit.label')}
                                </Button>
                                {isFeatureEnabled(AppFeatures.REGISTRATION) && (
                                    <Box fontSize={'16px'}>
                                        <Text>
                                            {content(
                                                'forms.registration.fields.link.note'
                                            )}
                                        </Text>
                                        <Button
                                            variant={'outline'}
                                            onClick={() => {
                                                setToRegister(true);
                                            }}
                                        >
                                            {content(
                                                'forms.registration.fields.link.label'
                                            )}
                                        </Button>
                                    </Box>
                                )}
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default Login;
