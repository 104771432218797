import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { AppRoutes, QueryKeys } from '../config/config';
import { fetchSecurityArticles } from '../services/apiService';
import useErrorToast from '../hooks/useErrorToast';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { ArticleTemplate } from '../config/interface';
import ArticleBlock from '../components/discover/ArticleBlock';
const DiscoverArticlePage = () => {
    const { slug } = useParams<{ slug: string }>();
    const errorToast = useErrorToast();

    // TODO: THIS IS EXPENSIVE. REQUEST API TO GET SPECIFIC ARTICLE INSTEAD
    const { data: topics, isLoading } = useQuery(
        QueryKeys.articles.list('all'),
        () => fetchSecurityArticles({ topic: 'all' }),
        {
            onError: topicError => {
                errorToast(topicError as AxiosError<Error>);
            },
        }
    );

    const articleTemplate = useMemo(
        () =>
            topics
                ?.find(({ slug: topicSlug }) => topicSlug === slug)
                ?.articleTemplate?.map(
                    ({ sys, fields }) =>
                        ({
                            fields,
                            blockType: sys.contentType.sys.id,
                        } as ArticleTemplate)
                ),
        [slug, topics]
    );

    return (
        <Box w={'100%'} position={'relative'} id={`discover-article-page-${slug || ''}`}>
            <Box
                display={{ base: 'block', md: 'none' }}
                backgroundColor={'white'}
                p={'14px 0 14px 14px'}
            >
                <Breadcrumbs
                    items={[
                        { label: 'Article', href: AppRoutes.DISCOVER, current: true },
                    ]}
                    currentOnly={true}
                />
            </Box>
            <Box
                display={{ base: 'none', md: 'block' }}
                backgroundColor={'white'}
                p={'14px 0 14px 14px'}
            >
                <Breadcrumbs
                    items={[
                        { label: 'Dashboard', href: AppRoutes.DASHBOARD, current: false },
                        {
                            label: 'Discover Security',
                            href: AppRoutes.DISCOVER,
                            current: true,
                        },
                    ]}
                    currentOnly={false}
                />
            </Box>
            {isLoading ? (
                <Flex w="full" h="60vh" align="center" justify="center">
                    <Spinner size="xl" />
                </Flex>
            ) : (
                articleTemplate?.map((template, i) => (
                    <ArticleBlock {...template} key={`article-${i}`} />
                ))
            )}
        </Box>
    );
};

export default DiscoverArticlePage;
