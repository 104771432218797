import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Heading,
    Stack,
    Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpForm from '../components/common/OtpForm';
import Header from '../components/header/Header';
import FullWidth from '../components/layouts/FullWidth';
import { AppRoutes, MDN_STATUS } from '../config/config';
import { NullableVar } from '../config/interface';

import { registerMdn } from '../entities/session';
import useContent from '../hooks/useContent';
import useErrorToast from '../hooks/useErrorToast';
import { verifyRegisteredMobileNumber, verifySmsOtp } from '../services/apiService';

const enum ScreenState {
    DEFAULT,
    NO_SECURITY_ADVISOR,
    ALREADY_REGISTERED,
    NOT_REGISTERED,
}

const VerifyMobileOtpPage = () => {
    const content = useContent();
    const errToast = useErrorToast();
    const navigate = useNavigate();
    const mdn = registerMdn.use();
    const [otp, setOtp] = useState();
    const [invalidOtp, setInvalidOtp] = useState<NullableVar<boolean>>();
    const [screenState, setScreenState] = useState(ScreenState.DEFAULT);
    const [email, setEmail] = useState<string>('');

    const { mutate: verifyMdn, isLoading: verifyingMdn } = useMutation({
        mutationFn: verifyRegisteredMobileNumber,
        onError: (error: AxiosError<Error>) => {
            errToast(error);
        },
    });

    const { mutate: verifyOtp, isLoading: verifyingOtp } = useMutation({
        mutationFn: verifySmsOtp,
        onSuccess: data => {
            const { otpStatus, registrationStatus, registeredEmail } = data;
            if (otpStatus) {
                setInvalidOtp(false);
                switch (registrationStatus) {
                    case MDN_STATUS.NOT_REGISTERED:
                        setScreenState(ScreenState.NOT_REGISTERED);
                        break;
                    case MDN_STATUS.NO_SECURITY_ADVISOR:
                        setScreenState(ScreenState.NO_SECURITY_ADVISOR);
                        break;
                    case MDN_STATUS.ALREADY_REGISTERED:
                        setScreenState(ScreenState.ALREADY_REGISTERED);
                        setEmail(registeredEmail);
                        break;
                }
            } else {
                setInvalidOtp(true);
            }
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
        },
    });

    const backToLogin = useCallback(() => {
        navigate(AppRoutes.LOGIN);
    }, [navigate]);

    useEffect(() => {
        if (screenState === ScreenState.NOT_REGISTERED) {
            // redirect to register email
            navigate(AppRoutes.REGISTRATION_MDN.replace(':mdn', mdn || ''));
        }
    }, [navigate, screenState, mdn]);

    return (
        <FullWidth id={'verify-mobile-otp-page'}>
            <Header authorized={false}></Header>
            <Box marginTop={{ base: '84px', md: '112px' }}>
                <Card w={{ base: '343px', md: '464px' }} m={'0 auto'}>
                    <CardHeader bgGradient="linear(91.62deg, grayscale.400, grayscale.600)">
                        {screenState === ScreenState.DEFAULT && (
                            <>
                                <Heading
                                    as="h2"
                                    mb={2}
                                    fontSize={24}
                                    noOfLines={1}
                                    display="inline-flex"
                                    gap={2}
                                >
                                    {content('forms.otp.header.title')}
                                </Heading>
                                <Stack spacing={2}>
                                    <Text fontSize="1em">
                                        {content(
                                            'forms.registration.verify.otp.content1'
                                        )}
                                    </Text>
                                    <Text fontSize="1em">
                                        {content(
                                            'forms.registration.verify.otp.content2',
                                            '{mobile}',
                                            (match, i) => {
                                                return (
                                                    <strong key={`mobile-${i}`}>
                                                        {mdn}
                                                    </strong>
                                                );
                                            }
                                        )}
                                    </Text>
                                </Stack>
                            </>
                        )}
                        {screenState === ScreenState.NO_SECURITY_ADVISOR && (
                            <>
                                <Stack spacing={2}>
                                    <Text fontSize="1em">
                                        {content(
                                            'forms.registration.verify.message.unsubscribe.content1'
                                        )}
                                    </Text>
                                    <Text fontSize="1em">
                                        {content(
                                            'forms.registration.verify.message.unsubscribe.content2'
                                        )}
                                    </Text>
                                </Stack>
                            </>
                        )}
                        {screenState === ScreenState.ALREADY_REGISTERED && (
                            <>
                                <Stack spacing={2}>
                                    <Text fontSize="1em">
                                        {content(
                                            'forms.registration.verify.message.registered.content1'
                                        )}
                                    </Text>
                                    <Text fontSize="1em">
                                        {content(
                                            'forms.registration.verify.message.registered.content2',
                                            '{email}',
                                            (match, i) => {
                                                return (
                                                    <span key={`email-${i}`}>
                                                        <strong>&nbsp;{email}</strong>
                                                    </span>
                                                );
                                            }
                                        )}
                                    </Text>
                                </Stack>
                            </>
                        )}
                    </CardHeader>
                    <CardBody>
                        {screenState === ScreenState.DEFAULT && (
                            <OtpForm
                                onFormSubmit={e => {
                                    e.preventDefault();
                                    verifyOtp({ mdn: mdn || '', otp: otp || '' });
                                }}
                                rootProps={{ spacing: 4 }}
                                label={content('forms.otp.fields.otp.label')}
                                otpCount={6}
                                disclaimer={content(
                                    'forms.registration.verify.otp.fields.code.helper',
                                    '{{minute}}',
                                    (match, i) => (
                                        <span key={`content-${i}`}>1 minute</span>
                                    )
                                )}
                                buttonDisabled={false}
                                buttonLabel={'Continue'}
                                isInvalid={invalidOtp || false}
                                isLoading={verifyingOtp}
                                onChange={str => {
                                    setOtp(str);
                                    setInvalidOtp(false);
                                }}
                                onComplete={() => {
                                    console.log('complete');
                                }}
                                errorMessage={'Invalid OTP entered. Please try again.'}
                            />
                        )}
                        {screenState === ScreenState.DEFAULT && (
                            <Button
                                variant={'link'}
                                color={'#111'}
                                fontSize={'18px'}
                                fontWeight={700}
                                mt={'24px'}
                                isLoading={verifyingMdn}
                                onClick={() => verifyMdn({ mdn: mdn || '' })}
                            >
                                Send a new code
                            </Button>
                        )}
                        {(screenState === ScreenState.NO_SECURITY_ADVISOR ||
                            screenState === ScreenState.ALREADY_REGISTERED) && (
                            <Button variant={'solid'} onClick={backToLogin}>
                                Back to login
                            </Button>
                        )}
                    </CardBody>
                </Card>
            </Box>
        </FullWidth>
    );
};

export default VerifyMobileOtpPage;
