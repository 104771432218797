import { Api as apiUrls } from './api';
import { AppRoutes as appRoutes, PublicRoutes } from './routes';
import { AppFeatures as appFeatures } from './features';

export const Api = apiUrls;
export const AppRoutes = appRoutes;
export const AppFeatures = appFeatures;
export const AppPublicRoutes = PublicRoutes;

export const AppConfig = {
    name: process.env.REACT_APP_TITLE,
    isMock: process.env.REACT_APP_IS_MOCK,
    storagePrefix: 'sec_adv',
    carrier: process.env.REACT_APP_CARRIER,
    program: process.env.REACT_APP_PROGRAM,
    baseRoute: process.env.REACT_APP_BASE_ROUTE,
};

export const DASHBOARD_SECTIONS = {
    WELCOME: 'welcome',
    OVERVIEW: 'overview',
    DISCOVER: 'discover',
};

export const AppComponents = {
    CHAT: 'chat',
};

export const LoginRedirects = {
    BREACH: { key: 'breach', redirect: AppRoutes.BREACH },
    ASSESSMENT: { key: 'assessment', redirect: AppRoutes.ASSESSMENT },
    PROFILE: { key: 'profile', redirect: AppRoutes.PROFILE },
    DISCOVER: { key: 'discover-security', redirect: AppRoutes.DISCOVER },
    CHAT: { key: 'chat', component: 'chat' },
};

export const RISK_LEVEL = {
    HIGH: 'HIGH',
    MEDIUM: 'MEDIUM',
    LOW: 'LOW',
};

export type RiskLevel = keyof typeof RISK_LEVEL;

export const LOADER_TYPE = {
    SPINNER: 'spinner',
    SKELETON: 'skeleton',
};

export const ASSET_CRITERIA = {
    RESOLVED: 'resolved',
    EXPOSED: 'exposed',
};

export const QueryKeys = {
    assessments: {
        all: ['assessments'] as const,
        questions: ['assessments', 'questions'] as const,
        result: (email: string) => ['assessments', 'result', email] as const,
        guide: (data: { category: string; guide: string }) =>
            ['assessments', 'guide', data] as const,
        summary: (email: string) => ['assessment', 'summary', email] as const,
    },
    account: (email: string) => ['account', email] as const,
    articles: {
        list: (topic?: string) => ['articles', 'list', topic] as const,
        topics: ['articles', 'topics'] as const,
    },
};

export const MDN_STATUS = {
    NOT_REGISTERED: 'NOT_REGISTERED',
    NO_SECURITY_ADVISOR: 'NO_SECURITY_ADVISOR',
    ALREADY_REGISTERED: 'ALREADY_REGISTERED',
};

export const REGISTRATION_STATUS = {
    NOT_EXISTING_EMAIL: 'NOT_EXISTING_EMAIL',
    ALREADY_EXISTING_EMAIL: 'ALREADY_EXISTING_EMAIL',
};
