import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { getResponseError } from '../utils/helpers';
import { account } from '../entities/session';
import { IAccountEmail, IAssets, IBreadcrumbItem } from '../config/interface';
import { getPageStyles } from '../config/theme';
import { useQuery } from '@tanstack/react-query';
import { fetchAllAssets } from '../services/apiService';
import BreachAlerts from '../components/breach/BreachAlerts';
import BreachDataDisplay from '../components/breach/BreachDataDisplay';
import BreachWelcomeBoard from '../components/breach/BreachWelcomeBoard';
import BreachTabs from '../components/breach/BreachTabs';
import { breachAssets, exposedCount } from '../entities/assets';
import BreachPageMainIconSvg from '../assets/BreachPageMainIconSvg';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { AppRoutes } from '../config/config';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

const BreachPage = () => {
    const doLogout = useLogout();
    const [searchParams] = useSearchParams();
    const hash = searchParams.get('scan') || null;

    let accountInfo = account.use();
    let breachAssetsData = breachAssets.use();
    const { main } = getPageStyles('BreachPage');

    const {
        addEmail: [openAddEmail, setOpenAddEmail],
    } = useOutletContext<any>();

    const [selectedEmail, setSelectedEmail] = useState<IAccountEmail>({
        email: '',
        lastScanDate: 0,
        resolved: 0,
        exposed: 0,
        tag: '',
    });
    const [primaryEmail, setPrimaryEmail] = useState<IAccountEmail>({
        email: '',
        lastScanDate: 0,
        resolved: 0,
        exposed: 0,
        tag: '',
    });
    const [assets, setAssets] = useState<any>();
    const [emails, setEmails] = useState<IAccountEmail[]>();

    // rescan means you will invoke api with scan = true
    const [rescan, setRescan] = useState<boolean>(false);
    // refetch means you just want to fetch asset again
    const [refetch, setRefetch] = useState<boolean>(false);

    const breadcrumbItems: IBreadcrumbItem[] = [
        { label: 'Dashboard', href: AppRoutes.DASHBOARD, current: false },
        { label: 'Data Breach Monitoring', href: AppRoutes.BREACH, current: true },
    ];

    const parseAllEmails = useCallback(({ primaryEmail, secondaryEmails }) => {
        let allEmails: any[] = [];
        let email = primaryEmail;
        email.tag = email?.tag || 'Primary Email';

        allEmails.push(email);
        for (let x = 0; x < secondaryEmails?.length; x++) {
            const email = secondaryEmails[x];
            email.tag = email?.tag || 'Secondary Email';

            allEmails.push(email);
        }

        // setEmails(allEmails);

        return allEmails;
    }, []);

    const { isFetching: assetFetching, refetch: refetchAsset } = useQuery<IAssets>(
        ['assets'],
        () => {
            return fetchAllAssets({ email: selectedEmail?.email, rescan: rescan });
        },
        {
            retry: 0,
            enabled: selectedEmail && selectedEmail.email ? true : false,
            onError: assetError => {
                const { status } = getResponseError(assetError);
                if (status === 401) {
                    doLogout();
                }
            },
            onSuccess: (assetData: IAssets) => {
                setRescan(false);

                const {
                    assets: fetchedAssets,
                    exposed,
                    resolved,
                    secondaryEmail,
                    lastScanDate,
                } = assetData;

                setAssets(fetchedAssets || {});

                // setting up selected email
                let emailData = selectedEmail || primaryEmail;
                emailData.exposed = exposed || 0;
                emailData.resolved = resolved || 0;
                emailData.lastScanDate = lastScanDate || 0;
                setSelectedEmail(emailData);

                if (selectedEmail.email === primaryEmail.email) {
                    setEmails(
                        parseAllEmails({
                            primaryEmail: accountInfo?.primaryEmail,
                            secondaryEmails: secondaryEmail,
                        })
                    );

                    // update also entities - breach assets
                    breachAssets.set(assetData);

                    // update also entities - account
                    const newAccountInfo = accountInfo;
                    newAccountInfo.primaryEmail.exposed = exposed;
                    newAccountInfo.primaryEmail.resolved = resolved;
                    newAccountInfo.secondaryEmails = breachAssetsData?.secondaryEmail;
                    account.set(newAccountInfo);

                    exposedCount.set(exposed);
                } else {
                    // update also entities - account
                    const newAccountInfo = accountInfo;
                    newAccountInfo.secondaryEmails = breachAssetsData.secondaryEmail;
                    account.set(newAccountInfo);
                }
            },
        }
    );

    const onEmailChange = useCallback(
        ({ email, lastScanDate }) => {
            if (selectedEmail.email !== email) {
                setSelectedEmail({ email, lastScanDate });
                setRefetch(true);
            }
        },
        [selectedEmail]
    );

    const onRescan = useCallback(() => {
        setRescan(true);
        setRefetch(true);
    }, []);

    useEffect(() => {
        if (accountInfo && selectedEmail.email === '') {
            setSelectedEmail(accountInfo.primaryEmail);

            setPrimaryEmail(
                accountInfo?.primaryEmail?.email
                    ? accountInfo?.primaryEmail
                    : { email: accountInfo?.primaryEmail, lastScanDate: 0 }
            );

            setEmails(
                parseAllEmails({
                    secondaryEmails: accountInfo?.secondaryEmails,
                    primaryEmail: accountInfo?.primaryEmail,
                })
            );

            if (hash) {
                if (hash === accountInfo.primaryEmail.email) {
                    setSelectedEmail(accountInfo.primaryEmail);
                } else {
                    for (let x = 0; x < accountInfo.secondaryEmails.length; x++) {
                        const { email } = accountInfo.secondaryEmails[x];

                        if (email === hash) {
                            setSelectedEmail(accountInfo.secondaryEmails[x]);
                        }
                    }
                }
            }
        }
    }, [selectedEmail, accountInfo, parseAllEmails, rescan, hash]);

    useEffect(() => {
        // refetch
        if (refetch && !assetFetching) {
            setRefetch(false);
            refetchAsset();
        }
    }, [refetch, rescan, assetFetching, refetchAsset, selectedEmail]);

    // console.log('selected email', selectedEmail);

    return (
        <Box id={'breach-page'} {...main}>
            <Flex direction={'column'} gap={0}>
                <Box
                    display={{ base: 'block', md: 'none' }}
                    backgroundColor={{ base: 'white', md: '#F0F0F5' }}
                    p={{ base: '14px 0 0 14px', md: '24px 0 0 24px' }}
                >
                    <Breadcrumbs items={breadcrumbItems} />
                </Box>

                <BreachWelcomeBoard
                    selectable={true}
                    email={selectedEmail || primaryEmail}
                    emails={emails}
                    onEmailChange={onEmailChange}
                    onRescan={onRescan}
                    pageIcon={<BreachPageMainIconSvg />}
                />

                {emails && emails.length > 0 && (
                    <BreachTabs
                        selectedEmail={selectedEmail}
                        emails={emails}
                        onTabChange={onEmailChange}
                        isLoading={assetFetching}
                        onAddEmail={() => {
                            if (!assetFetching) {
                                setOpenAddEmail(!openAddEmail);
                            }
                        }}
                    />
                )}

                {assetFetching ? (
                    <Box width={'100%'} textAlign={'center'} mt={'15%'}>
                        <Spinner variant={'pageLoader'} />
                    </Box>
                ) : (
                    <>
                        {selectedEmail && selectedEmail.email && (
                            <BreachAlerts
                                email={selectedEmail.email}
                                exposed={selectedEmail.exposed}
                                resolved={selectedEmail.resolved}
                            />
                        )}
                        {selectedEmail && selectedEmail.email && (
                            <BreachDataDisplay
                                assets={assets}
                                selectedEmail={selectedEmail}
                            />
                        )}
                    </>
                )}
            </Flex>
        </Box>
    );
};

export default BreachPage;
