import React, { useState } from 'react';
import FullWidth from '../components/layouts/FullWidth';
// import { authToken } from '../entities/session';
import Login from '../components/login/Login';
import { useMutation } from '@tanstack/react-query';
import { login } from '../services/apiService';
import { NullableVar } from '../config/interface';
import { Navigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../config/config';
import { authToken, loginEmail, postLoginRedirect } from '../entities/session';
import Header from '../components/header/Header';
import { Box } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import useErrorToast from '../hooks/useErrorToast';

const LoginPage: React.FC = ({ ...props }) => {
    const { redirectTo } = useParams<{ redirectTo: string }>();
    postLoginRedirect.set(redirectTo || null);

    const [authorized, setAuthorized] = useState<NullableVar<boolean>>(null);

    const errToast = useErrorToast();

    const { mutate: doLogin, isLoading: loginFetching } = useMutation({
        mutationFn: login,
        onSuccess: data => {
            const { authenticated } = data;
            if (authenticated === true) {
                setAuthorized(authenticated);
            }
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
        },
    });

    return (
        <FullWidth id={'login-page'}>
            {authorized === true && <Navigate to={AppRoutes.OTP} />}
            <Header authorized={false}></Header>

            <Box marginTop={{ base: '84px', md: '112px' }}>
                <Login
                    onSubmit={email => {
                        authToken.set(null);
                        setAuthorized(null);
                        loginEmail.set(email);
                        doLogin(email);
                    }}
                    isAuthenticating={loginFetching}
                />
            </Box>
        </FullWidth>
    );
};

export default LoginPage;
