import { Center, Stack, Text } from '@chakra-ui/react';
import { BLOCKS } from '@contentful/rich-text-types';
import { VideoOrDescriptionBlockProps } from '../../config/interface';
import { articleHeadingNodeOptions } from '../../utils/contentfulRenderOptions';
import renderDocument from '../../utils/renderDocument';

// TODO: Handle video
const ArticleVideoDescriptionBlock: React.FC<VideoOrDescriptionBlockProps> = ({
    description,
    backgroundColor = 'transparent',
    fontColor,
    fontSize = '26px',
}) => {
    return (
        <Center pt={20} px={{ base: 8, md: 20 }} pb={16} bg={backgroundColor}>
            <Stack align="center" spacing={10}>
                {renderDocument(description, {
                    renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                            <Text
                                fontSize={fontSize}
                                color={fontColor}
                                textAlign="center"
                                maxW="3xl"
                                lineHeight={8}
                            >
                                {children}
                            </Text>
                        ),
                        ...articleHeadingNodeOptions,
                    },
                })}
            </Stack>
        </Center>
    );
};

export default ArticleVideoDescriptionBlock;
