import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { MessageProps, MessageTypes } from '../types';

export const Message: React.FC<MessageProps> = ({ children, type }) => {
    let boxProps = {};

    switch (type.toUpperCase()) {
        case MessageTypes.SYSTEM: {
            boxProps = {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 20,
                alignSelf: 'start',
            };
            break;
        }

        case MessageTypes.USER: {
            boxProps = {
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 0,
                alignSelf: 'end',
            };
            break;
        }
    }

    return (
        <Box
            px={'17px'}
            py={'15px'}
            borderWidth={1}
            borderColor={'#D5D6Da'}
            borderTopRadius={20}
            wordBreak={'break-all'}
            {...boxProps}
        >
            {children}
        </Box>
    );
};

export default Message;
