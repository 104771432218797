import { LinkProps, Link } from '@chakra-ui/react';
import { LinkProps as RouterLinkProps, Link as RouterLink } from 'react-router-dom';
import React from 'react';

const RouteLink: React.FC<LinkProps & RouterLinkProps> = ({ children, ...props }) => (
    <Link
        as={RouterLink}
        textDecoration={'none'}
        _hover={{ textDecoration: 'none' }}
        {...props}
    >
        {children}
    </Link>
);

export default RouteLink;
