import { Center, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import { ArticleTemplate, BlockType } from '../../config/interface';
import ArticleBanner from './ArticleBanner';
import ArticleTwoColumnBlock from './ArticleTwoColumnBlock';
import ArticleVideoDescriptionBlock from './ArticleVideoDescriptionBlock';

const ArticleBlock: React.FC<ArticleTemplate> = data => {
    switch (data.blockType) {
        case BlockType.Banner:
            return <ArticleBanner {...data.fields} />;
        case BlockType.VideoOrDescription:
            return <ArticleVideoDescriptionBlock {...data.fields} />;
        case BlockType.TwoColumn:
            return <ArticleTwoColumnBlock {...data.fields} />;
        default:
            return process.env.NODE_ENV === 'development' ? (
                <Center
                    p={8}
                    bg="red.300"
                    border="1px solid"
                    borderColor="red.900"
                    flexDir="column"
                >
                    <Text fontWeight="bold" color="red.900">
                        No renderer for {(data as any)?.blockType}
                    </Text>
                    <SimpleGrid columns={2} minW={{ base: 0, md: '500px' }}>
                        {Object.entries((data as any)?.fields || {}).map(
                            ([key, value]) => (
                                <>
                                    <Text>{key}</Text>
                                    <Text>{value + ''}</Text>
                                </>
                            )
                        )}
                    </SimpleGrid>
                </Center>
            ) : null;
    }
};

export default ArticleBlock;
