/* eslint-disable no-console */
import { Box, Flex, Text, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import ChatWidget from '../../chat';
import { appSyncChatConfig } from '../../chat/services/ConfigService';
import {
    AppComponents,
    AppFeatures,
    AppRoutes,
    LoginRedirects,
} from '../../config/config';
import { getPageStyles } from '../../config/theme';
import { account, authToken, postLoginRedirect } from '../../entities/session';
import useLogout from '../../hooks/useLogout';
import { createUrl, isFeatureEnabled } from '../../utils/helpers';
import AddEmailModal from '../common/AddEmailModal';
import AddEmailVerifyModal from '../common/AddEmailVerifyModal';
import ConfirmDialog from '../common/ConfirmDialog';
import Header from '../header/Header';
import SidebarNav from '../sidebar/SidebarNav';

const ProtectedPage: React.FC = () => {
    const doLogout = useLogout();
    const accountData = account.use();
    const token = authToken.use();
    const toast = useToast();
    const redirectTo = postLoginRedirect.use();

    const { email: emailToVerify } = useParams<{ email: string }>();
    const authorized = accountData && accountData?.primaryEmail && token ? true : false;
    const { main } = getPageStyles('ProtectedPageLayout');

    const [openAddEmail, setOpenAddEmail] = useState(false);
    const [logout, setLogout] = useState(false);
    const [openVerifyEmail, setOpenVerifyEmail] = useState(emailToVerify ? true : false);
    const [openChat, setOpenChat] = useState<boolean>(false);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState<boolean>(false);

    useEffect(() => {
        if (logout) {
            doLogout();
        }
        if (redirectTo) {
            const { component } = LoginRedirects[redirectTo.toUpperCase()];
            if (component === AppComponents.CHAT) {
                postLoginRedirect.set(null);
                setOpenChat(true);
            }
        }
    }, [logout, doLogout, redirectTo, openChat]);

    return (
        <Box>
            {!authorized && <Navigate to={AppRoutes.INDEX} replace />}
            {authorized && (
                <Header
                    authorized={authorized}
                    onLogout={() => setShowLogoutConfirm(true)}
                />
            )}
            {logout && (
                <Box
                    position={'absolute'}
                    top={0}
                    left={0}
                    w={'full'}
                    h={'full'}
                    textAlign={'center'}
                    zIndex={'999'}
                >
                    <Spinner margin={'30% auto'} variant={'pageLoader'} />
                    <Box
                        float={'none'}
                        position={'absolute'}
                        w={'full'}
                        h={'full'}
                        backgroundColor={'white'}
                        opacity={'0.5'}
                        top={0}
                        left={0}
                        zIndex={'-9'}
                    />
                </Box>
            )}
            {authorized && (
                <>
                    <ConfirmDialog
                        title={'Sign Out'}
                        isOpen={showLogoutConfirm}
                        onClose={() => {
                            setShowLogoutConfirm(false);
                        }}
                        onNo={() => {
                            setShowLogoutConfirm(false);
                        }}
                        onYes={() => {
                            setShowLogoutConfirm(false);
                            doLogout();
                        }}
                    >
                        <Text>Are you sure you want to sign out?</Text>
                    </ConfirmDialog>
                    <AddEmailModal
                        isOpen={openAddEmail}
                        onClose={() => {
                            setOpenAddEmail(false);
                        }}
                    />
                    <AddEmailVerifyModal
                        isOpen={openVerifyEmail}
                        onClose={() => {
                            setOpenVerifyEmail(false);
                        }}
                        emailToVerify={emailToVerify}
                        onVerifiedFailed={() => {
                            setOpenVerifyEmail(false);
                        }}
                        onVerifiedSuccess={() => {
                            setOpenVerifyEmail(false);
                            toast({
                                status: 'success',
                                title: `Your have successfully added a secondary email ${emailToVerify}`,
                            });

                            setTimeout(() => {
                                window.location.href = createUrl(
                                    `${AppRoutes.BREACH}?scan=${emailToVerify}`
                                );
                            }, 2000);
                        }}
                    />
                    <Flex {...main}>
                        <SidebarNav />
                        <Outlet
                            context={{
                                doLogout: [logout, setLogout],
                                addEmail: [openAddEmail, setOpenAddEmail],
                            }}
                        />
                        {isFeatureEnabled(AppFeatures.CHAT) && (
                            <ChatWidget
                                open={openChat}
                                appSyncConfig={appSyncChatConfig}
                            />
                        )}
                    </Flex>
                </>
            )}
        </Box>
    );
};

export default ProtectedPage;
