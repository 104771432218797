// TWILIO -> getAuthToken, createTask, available Agents

import { noop } from '../utils/helpers';
import { DoubleLinkedList } from 'scl';
import { Nullable } from '../utils/types';
import {
    AppSyncChatConfig,
    AuthorizationInput,
    Callback,
    ChatMessage,
    ChatRequest,
    ChatStatus,
    CreateChatRequest,
    EngagementStatusCode,
    SubscriptionMessage,
} from './types';
import md5 from 'md5';
import Observable from 'zen-observable';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { AppSyncHelper } from './services/ChatService';
import { HmacSHA256 } from 'crypto-js';
import { compareAsc } from 'date-fns';
import axios, { AxiosInstance, Method } from 'axios';

export class ChatManager {
    private subscriptions: DoubleLinkedList<Observable.Subscription>;

    _axios: AxiosInstance;

    set axios(value) {
        this._axios = value;
    }

    get axios() {
        return this._axios;
    }

    activeChat: boolean = false;

    requestId!: string;
    cognitoId!: string;
    conversationId!: string;
    visitorId!: string;
    skillId: string;
    clientId!: Nullable<string>;
    language: `${string}-${string}`;
    engagementType: string;
    region!: string;
    chatType!: Nullable<string>;
    mdn!: string;
    status!: ChatStatus;
    getTokenApiKey: string = 'c14c2c592a34e4dbe4c2aa525bd0efe8';
    hua: string;
    isResolved!: boolean;
    source: string = 'Web';
    carrier: string = 'True';
    programName: string = 'DP';
    chatReason: string = 'Technical Support';
    userName: string = 'User';

    onConnecting: Callback = noop;
    onAnswered: Callback = noop;
    onConnected: Callback = noop;
    onDisconnected: Callback = noop;
    onMessageReceived: Callback<ChatMessage> = noop;
    onMessageUpdated: Callback<ChatMessage> = noop;
    onStatusUpdated: Callback = noop;
    onAgentTyping: Callback = noop;

    constructor(
        public client: string,
        public environment: string,
        public engagementApiConfig: {
            engagementApiUrl: URL;
            path: string;
            program: string;
            channel: string;
            engagementApiKey: string;
        },
        {
            skillId = null,
            chatType = null,
            language = null,
            engagementType = null,
            clientId = null,
            userName = null,
        }: Pick<AppSyncChatConfig, 'skillId' | 'chatType' | 'clientId'> & {
            language?: Nullable<`${string}-${string}`>;
            engagementType?: Nullable<string>;
            userName?: Nullable<string>;
        }
    ) {
        this.chatType = chatType;
        this.language = language ?? 'en-US';
        this.chatType = chatType ?? 'SOLUTO';
        this.engagementType = engagementType ?? 'CHAT';

        this.skillId = skillId
            ? skillId
            : (
                  this.client +
                  '-' +
                  this.chatType +
                  '-' +
                  this.engagementType
              ).toUpperCase();
        this.clientId = clientId;

        this.hua = md5(navigator.userAgent);

        this.subscriptions = new DoubleLinkedList<Observable.Subscription>();
        this.updateStatus(ChatStatus.Initialized);

        this._axios = axios.create({
            baseURL: `${this.engagementApiConfig.engagementApiUrl.protocol}//${this.engagementApiConfig.engagementApiUrl.host}${this.engagementApiConfig.engagementApiUrl.pathname}`,
            headers: {
                'content-type': 'application/json',
                'x-client-key': engagementApiConfig.engagementApiKey,
                'x-nvu-client': true,
                'x-nvu-program': engagementApiConfig.program,
                'x-nvu-channel': engagementApiConfig.channel,
            },
        });
    }

    private updateStatus = (status: ChatStatus) => {
        this.status = status;
        this.onStatusUpdated();
    };

    public async createVisitor(visitorId: string) {
        var m = `mutation createVisitor($input:CreateVisitorInput!){
                   createVisitor(input:$input){
                      visitorCognitoId
                      visitorId
                      userName
                      clientId
                      languageCode
                      browserAgent
                      ipAddress
                      journeyStatus
                      mdn
                  }
                }`;

        var input = {
            startTimestamp: new Date().toISOString(),
            visitorId,
            languageCode: this.language,
            visitorCognitoId: this.region + uuid(),
            userName: uuid(),
            clientId: this.clientId ?? uuid(),
            browserAgent: navigator.userAgent,
            ipAddress: '127.0.0.1',
            journeyStatus: 'Started',
            mdn: this.mdn,
        };

        return await API.graphql<any>(graphqlOperation(m, { input }));
    }

    public hasActiveChat = (): boolean => {
        return this.activeChat;
    };

    private listenForConversation = (requestId: string) => {
        let observable: Observable<any> = null;
        let subscription: Observable.Subscription = null;

        try {
            observable = AppSyncHelper.subscribe(
                this.createConversationGraphQL,
                '{requestId}',
                requestId
            );
            subscription = observable.subscribe((data: SubscriptionMessage<any>) => {
                let onCreateConversation = data.value.data.onCreateConversation;
                let conversationId = onCreateConversation.conversationId;
                let visitorId = onCreateConversation.visitorId;

                this.conversationId = conversationId;
                this.visitorId = visitorId;

                console.log('Chat Accepted: ' + conversationId);

                this.createChatAudit('Chat Acknowledged', requestId, visitorId);

                //connect to created conversation
                this.connect();

                this.onAnswered?.();
            });
        } catch (e) {
            console.error(e);
        }
        this.subscriptions.add(subscription);
    };

    public create = async (
        activeChat: boolean,
        _requestId: Nullable<string> = null,
        _visitorId: Nullable<string> = null,
        _conversationId: Nullable<string> = null
    ) => {
        if (activeChat && !!_requestId && !!_conversationId) {
            return await this.connect(_requestId, _conversationId);
        } else {
            const result = await this.createVisitor(_visitorId ?? uuid());
            if (result) {
                const visitor = result.data.createVisitor;
                this.visitorId = visitor.visitor_id;
                const chatRequestId = await this.createTask(
                    visitor.visitor_id,
                    this.userName
                );
                this.updateStatus(ChatStatus.Connecting);
                this.onConnecting();

                console.log('BEFORE CREATE CHAT REQUEST RESULT');
                const createChatRequestResult = await this.createChatRequest(
                    chatRequestId,
                    visitor.visitorId,
                    uuid()
                );
                this.requestId = chatRequestId;
                this.visitorId = visitor.visitorId;
                this.listenForConversation(chatRequestId);
                console.log('CREATE', createChatRequestResult);
                return createChatRequestResult;
            }
        }
    };

    private createTask = async (
        visitorId: string,
        userName: string,
        source: string = this.source,
        carrier: string = this.carrier,
        programName: string = this.programName,
        chatReason: string = this.chatReason
    ) => {
        const data: CreateChatRequest = {
            mdn: this.mdn,
            visitorId: visitorId,
            userName,
            source,
            carrier,
            programName,
            chatReason,
            selectedLanguage: this.language,
        };
        console.log('DATA', data);
        const {
            data: { chatRequestId },
        } = await this.axios.post('/nvu/chat/task', data, {
            headers: {
                ...this.getSecurityHeaders(
                    '/nvu/chat/task',
                    'POST',
                    JSON.stringify(data)
                ),
            },
        });
        console.log('RESPONSE', chatRequestId);
        this.createChatAudit('Chat Initiated', chatRequestId, visitorId);
        return chatRequestId;
    };

    public hasAvailableAgents = async () => {
        const {
            data: { totalAvailableWorkers },
        } = await this.axios.get('/nvu/worker/stats', {
            headers: {
                ...this.getSecurityHeaders('/nvu/worker/stats'),
            },
        });
        return totalAvailableWorkers > 0;
    };

    private getSecurityHeaders = (
        path: string = '/',
        httpMethod: Method = 'GET',
        payload: Nullable<string | object> = null,
        timestamp: number = Date.now()
    ) => {
        return {
            'x-request-ts': timestamp,
            'x-nvu-authorization': ChatManager.generateAuthorization({
                origin: `${window.location.protocol}//${window.location.host}`,
                path: `${this.engagementApiConfig.path}${path}`,
                httpMethod,
                apiClientKey: this.engagementApiConfig.engagementApiKey,
                timestamp,
                payload,
            }),
        };
    };

    public createChatAudit = (auditLog: string, requestId: string, visitorId: string) => {
        var m = `mutation createChatAudit($input:CreateChatAudit!){
                    createChatAudit(input:$input){
                      visitorId
                      createdAt
                      auditLog
                      chatAuditId
                      expertName
                      requestId
                    }
                }`;

        var data = {
            auditLog: auditLog,
            createdAt: new Date().toISOString(),
            requestId: requestId,
            visitorId: visitorId,
        };

        var promise = new Promise((resolve, reject) => {
            resolve(API.graphql(graphqlOperation(m, { input: data })));
        });

        promise.then((result: any) => {
            console.log(
                'Chat audit Created: ' +
                    result.data.createChatAudit.chatAuditId +
                    auditLog
            );
        });
    };

    public async connect(
        requestId: string = this.requestId,
        conversationId: string = this.conversationId
    ) {
        let chatRequestData: ChatRequest;
        this.requestId = requestId;
        this.conversationId = conversationId;

        const data: any = await ChatManager.getChatRequest(this.requestId);
        // get the data of the chatRequest
        chatRequestData = data.data.getEncryptedChatRequest;

        // set the visitorId
        this.visitorId = chatRequestData?.visitorId;

        if (!!this.requestId && !!this.conversationId) {
            // create new conversation
            // a conversation id was provided, use it to subscribe to the conversation
            this.updateSubscriptions(this.requestId, this.conversationId);
            this.updateStatus(ChatStatus.Connected);
            this.onConnected?.();

            return this;
        } else {
            console.error('Request Id or Conversation Id is not set.');
        }
    }

    private updateSubscriptions(requestId: string, conversationId: string) {
        this.subscriptions.add(
            this.onMessageReceived &&
                this.observeCreateMessage(
                    conversationId,
                    this.onMessageReceived.bind(this)
                )
        );
        this.subscriptions.add(
            this.onMessageUpdated &&
                this.observeUpdateMessage(
                    conversationId,
                    this.onMessageUpdated.bind(this)
                )
        );
        this.subscriptions.add(
            this.onChatRequestUpdated &&
                this.observeChatRequest(requestId, () =>
                    this.onChatRequestUpdated.bind(this)
                )
        );

        this.subscriptions.add(
            this.onAgentTyping &&
                this.observeAgentTyping(conversationId, () => this.onAgentTyping())
        );
    }

    public sendMessage(message: string, messageData?: any): Promise<any> {
        return new Promise<any>((resolve: any, reject: any) => {
            var data = {
                messageId: uuid(),
                visitorId: this.visitorId,
                conversationId: this.conversationId,
                sender: 'Customer',
                source: 'Customer',
                content: message,
                recipient: 'Expert',
                createdAt: new Date().toISOString(),
                interactionType: 'Online',
                isSent: false,
                messageType: (messageData && messageData.messageType) || 'Plain Text',
                isActive: true,
                sourceLang: 'en',
                targetLang: 'en',
                translated: false,
            };

            API.graphql(graphqlOperation(this.createMessageGraphQL, { input: data }));
        });
    }

    private onChatRequestUpdated(chatRequest: ChatRequest) {
        this.processChatRequestUpdate(chatRequest);
    }

    private observeAgentTyping(
        conversationId: string,
        callback: Callback<boolean> = noop
    ): Observable.Subscription {
        let observable: Observable<any> = null;
        let isAgentTyping: boolean;
        let subscription: Observable.Subscription = null;

        observable = AppSyncHelper.subscribe(
            this.onAgentTypingGraphQL,
            '{conversationId}',
            conversationId
        );
        subscription = observable.subscribe((message: SubscriptionMessage<any>) => {
            // get the chat message
            isAgentTyping = message.value.data.onAgentTyping.agentTyping;
            if (isAgentTyping != null) {
                // the message was updated
                // do nothing
            } else {
                console.warn('No agent typing response');
            }
            // invoke the callback
            callback?.(isAgentTyping);
        });
        return subscription;
    }

    private observeChatRequest(
        requestId: string,
        callback: Callback<ChatRequest> = noop
    ): Observable.Subscription {
        let observable: Observable<any> = null;
        let subscription: Observable.Subscription = null;

        observable = AppSyncHelper.subscribe(
            this.onEndChatGraphQL,
            '{requestId}',
            requestId
        );
        subscription = observable.subscribe((data: SubscriptionMessage<any>) => {
            const chatRequest: ChatRequest = data.value.data
                .onUpdateChatRequest as ChatRequest;
            const chatResolved: boolean = this.isChatResolved(chatRequest);

            if (this.isChatEnding(chatRequest)) {
                this.unsubscribeSubscriptions();
                this.updateStatus(ChatStatus.Ended);
                this.isResolved = chatResolved;
                this.onDisconnected?.();
            }
            callback?.(chatRequest);
        });

        return subscription;
    }

    private unsubscribeSubscriptions = () => {
        for (const subscription of Array.from(this.subscriptions)) {
            subscription.unsubscribe();
        }
    };

    private processChatRequestUpdate(chatRequest: ChatRequest) {
        // check if the chat is ending based on the updates on the chatRequest
        const chatEnding: boolean = this.isChatEnding(chatRequest);
        const chatResolved: boolean = this.isChatResolved(chatRequest);
        if (chatEnding) {
            this.isResolved = chatResolved;
            this.onDisconnected?.();
        } else {
            this.isResolved = false;
        }
    }

    public getAllMessages(): Promise<Array<ChatMessage>> {
        return new Promise<Array<ChatMessage>>((resolve: any, reject: any) => {
            let allMessages: any[] = [];
            let messages: Array<ChatMessage>;
            let filtered: Array<ChatMessage>;

            if (this.conversationId != null && this.visitorId != null) {
                this.getMessagesByVisitorAndConversation(
                    this.visitorId?.toLowerCase(),
                    this.conversationId
                )
                    .then((resultLower: any) => {
                        messages = (resultLower.data.getMessages ??
                            []) as Array<ChatMessage>;
                        filtered = messages.filter((message: ChatMessage) => {
                            return true;
                        });
                        allMessages.push(...filtered);
                        this.getMessagesByVisitorAndConversation(
                            this.visitorId.toUpperCase(),
                            this.conversationId
                        )
                            .then((resultUpper: any) => {
                                messages = (resultUpper.data.getMessages ||
                                    []) as Array<ChatMessage>;
                                filtered = messages.filter((message: ChatMessage) => {
                                    return true;
                                });
                                allMessages.push(...filtered);
                                if (allMessages.length > 0) {
                                    allMessages.sort(this.messageSorter);
                                }
                                resolve(allMessages);
                            })
                            .catch((error: any) => {
                                resolve([]);
                            });
                    })
                    .catch((error: any) => {
                        resolve([]);
                    });
            } else {
                // return an empty message array
                resolve([]);
            }
        });
    }

    private messageSorter(a: ChatMessage, b: ChatMessage): number {
        return compareAsc(a.createdAt, b.createdAt);
    }

    private isChatEnding(chatRequest: ChatRequest): boolean {
        let isEnding: boolean = false;
        const statusCode: EngagementStatusCode =
            chatRequest.requestStatus as EngagementStatusCode;
        let statusCodeString: string;

        if (statusCode != null) {
            statusCodeString = statusCode.toString().toLowerCase();
            if (statusCodeString.indexOf('ended') > -1) {
                isEnding = true;
            } else if (statusCodeString.indexOf('abandoned') > -1) {
                isEnding = true;
            }
        } else if (chatRequest.rating != null) {
            //isEnding = true;
        } else {
            // unknown status code or not present or something else
            isEnding = false;
        }
        return isEnding;
    }

    private isChatResolved(chatRequest: ChatRequest): boolean {
        return (chatRequest.wrapUpCode ?? '').toLowerCase() === 'resolved';
    }

    private observeCreateMessage(
        conversationId: string,
        callback: Callback<ChatMessage> = noop
    ): Observable.Subscription {
        let observable: Observable<any> = null;
        let chatMessage: ChatMessage;
        let subscription: Observable.Subscription = null;

        if (callback != null) {
            observable = AppSyncHelper.subscribe(
                this.onCreateMessageGraphQL,
                '{conversationId}',
                conversationId
            );
            subscription = observable.subscribe((message: SubscriptionMessage<any>) => {
                // get the chat message
                chatMessage = message.value.data.onCreateMessage as ChatMessage;

                // invoke the callback
                callback(chatMessage);
            });
        }
        return subscription;
    }

    private observeUpdateMessage(
        conversationId: string,
        callback: Callback<ChatMessage> = noop
    ): Observable.Subscription {
        let observable: Observable<any> = null;
        let chatMessage: ChatMessage;
        let subscription: Observable.Subscription = null;

        observable = AppSyncHelper.subscribe(
            this.onUpdateMessageGraphQL,
            '{conversationId}',
            conversationId
        );
        subscription = observable.subscribe((message: SubscriptionMessage<any>) => {
            // get the chat message
            chatMessage = message.value.data.onUpdateMessage as ChatMessage;
            if (chatMessage != null) {
                // the message was updated
                // do nothing
            } else {
                console.warn('Empty message received?');
            }
            // invoke the callback
            callback?.(chatMessage);
        });
        return subscription;
    }

    //#region graphql

    private onUpdateMessageGraphQL = `
  subscription onUpdateMessage {
      onUpdateMessage(conversationId: "{conversationId}") {
          messageId
          visitorId
          conversationId
          messageType
          interactionType
          sender
          source
          content
          isSent
          recipient
          isActive
          createdAt
          sourceMsgId
          translated
          sourceLang
          targetLang
          messageStatus
          userResponseTime
      }
  }`;

    private onAgentTypingGraphQL = `
  subscription onAgentTyping {
    onAgentTyping(conversationId: "{conversationId}") {
      conversationId
      agentTyping
    }
  }`;

    private onEndChatGraphQL = `
  subscription onUpdateChatRequest {
      onUpdateChatRequest(requestId: "{requestId}") {
          requestId
          requestStatus
          wrapUpCode
          endTimestamp
      }
  }`;

    private onCreateMessageGraphQL = `
  subscription onCreateMessage {
      onCreateMessage(conversationId: "{conversationId}") {
              messageId
              visitorId
              conversationId
              messageType
              interactionType
              sender
              source
              content
              isSent
              recipient
              isActive
              createdAt
              sourceMsgId
              translated
              sourceLang
              targetLang
              messageStatus
              userResponseTime
      }
  }`;

    private createMessageGraphQL = `
  mutation createMessage($input:CreateMessageInput!) {
      createMessage(input:$input){
              messageId
              visitorId
              conversationId
              messageType
              interactionType
              sender
              source
              content
              isSent
              recipient
              isActive
              createdAt
              sourceMsgId
              translated
              sourceLang
              targetLang
              agentResponseTime
              userResponseTime
              violated
              messageStatus
              skillId
      }
  }`;

    private createConversationGraphQL = `
  subscription onCreateConversation {
      onCreateConversation(requestId: "{requestId}"){
          conversationId
          agentCognitoId
          requestId
          visitorId
          createdAt
          endTimestamp
      }
  }`;
    //#endregion

    private getMessagesByVisitorAndConversation = (
        visitorId: string,
        conversationId: string
    ) => {
        if (!visitorId || !conversationId) {
            console.log(
                'Undefined visitorId or conversationId',
                visitorId,
                conversationId
            );
            return new Promise((resolve, reject) => {
                reject('Undefined visitorId or conversationId');
            });
        }

        var query = `
              query getMessages {
                getMessages(visitorId: "{visitorId}", conversationId: "{conversationId}") {
                  messageId
                  visitorId
                  conversationId
                  messageType
                  messageStatus
                  interactionType
                  sender
                  source
                  content
                  isSent
                  recipient
                  isActive
                  createdAt
                  sourceMsgId
                  translated
                  sourceLang
                  targetLang
                  agentResponseTime
                  userResponseTime
                }
              }
              `;

        query = query
            .replace('{visitorId}', visitorId)
            .replace('{conversationId}', conversationId);

        return new Promise((resolve, reject) => {
            resolve(API.graphql(graphqlOperation(query)));
        });
    };

    public static getChatRequest = (requestId: string) => {
        if (!requestId) {
            console.log('Undefined requestId', requestId);
            return new Promise((resolve, reject) => {
                reject('Undefined requestId');
            });
        }

        var query = `
            query getEncryptedChatRequest {
                getEncryptedChatRequest(requestId: "{requestId}") {
                    acwStartTimestamp
                    appInBackground
                    ARN
                    averageResponseTime
                    averageUserResponseTime
                    browserAgent
                    caseNumber
                    chatAcceptTimeStamp
                    chatReason
                    chatType
                    chatWaitTime
                    claimNumber
                    clientName
                    comments
                    customerId
                    deviceId
                    endTimestamp
                    engagementDuration
                    engagementType
                    expertId
                    expertName
                    interactionId
                    isTransferred
                    languageCode
                    mdn
                    OSType
                    policyNumber
                    rating
                    requestChannel
                    requestId
                    requestStatus
                    requestType
                    skillId
                    speedToAnswer
                    startTimestamp
                    storeCode
                    taskId
                    transferredRequestId
                    userEmail
                    userProfileUrl
                    violationCount
                    visitorId
                    visitorName
                    wrapUpCode
                }
              }`;

        query = query.replace('{requestId}', requestId);
        return new Promise((resolve, reject) => {
            resolve(API.graphql(graphqlOperation(query)));
        });
    };

    public static getConversationByRequestId = (requestId: string) => {
        if (!requestId) {
            console.log('Undefined requestId', requestId);
            return new Promise((resolve, reject) => {
                reject('Undefined requestId');
            });
        }

        var query = `
            query getConversation {
                getConversation(requestId: "{requestId}") {
                    conversationId
                    agentCognitoId
                    requestId
                    visitorId
                    createdAt
                    endTimestamp
                    __typename
                }
              }`;

        query = query.replace('{requestId}', requestId);

        return new Promise((resolve, reject) => {
            resolve(API.graphql(graphqlOperation(query)));
        });
    };

    private createChatRequest = async (
        requestId: string,
        visitorId: string,
        taskId: string
    ) => {
        if (!requestId || !visitorId || !taskId) {
            console.log(
                'Undefined requestId/visitorId/taskId',
                requestId,
                visitorId,
                taskId
            );
            return new Promise((resolve, reject) => {
                reject('Undefined requestId/visitorId/taskId');
            });
        }

        var m = `
             mutation createEncryptedChatRequest($input:CreateChatRequestInput!){
                createEncryptedChatRequest(input:$input){
                    requestId
                    visitorId
                    taskId
                    visitorName
                    interactionId
                    mdn
                    customerId
                    skillId
                    languageCode
                    clientName
                    chatReason
                    requestStatus
                    requestType
                    chatWaitTime
                    startTimestamp
                    averageResponseTime
                    engagementDuration
                    violationCount
                    requestChannel
                    chatType
              }
            }`;

        var data = {
            chatReason: 'Technical Support',
            clientName: this.client,
            customerId: visitorId, //uuidv4(),
            engagementType: this.chatType,
            chatType: this.chatType,
            interactionId: '1122',
            languageCode: this.language,
            mdn: this.mdn,
            requestId: requestId,
            requestStatus: 'Initiated',
            requestType: 'Other',
            skillId: this.skillId,
            startTimestamp: new Date().toISOString(),
            visitorId: visitorId,
            visitorName: visitorId,
            taskId: taskId,
        };

        return await API.graphql(graphqlOperation(m, { input: data }));
    };

    public end = (): Promise<any> => {
        var m = `mutation updateEncryptedChatRequest($input: UpdateChatRequestInput!){
                updateEncryptedChatRequest(input:$input){
                        requestId
                        requestStatus
                        speedToAnswer
                        wrapUpCode
                        endTimestamp
                        expertName
                        userEmail
                        isTransferred
                        transferredRequestId
                        averageResponseTime
                        engagementDuration
                        violationCount
                        chatAcceptTimeStamp
                        expertId
                        averageUserResponseTime
                }
            }`;

        var data = {
            requestId: this.requestId,
            requestStatus: 'Ended',
            endTimestamp: new Date().toISOString(),
            wrapUpCode: 'User Disconnected',
        };

        return new Promise((resolve, reject) => {
            this.unsubscribeSubscriptions();
            this.updateStatus(ChatStatus.Ended);
            resolve(API.graphql(graphqlOperation(m, { input: data })));
        });
    };

    static generateAuthorization = ({
        origin = '',
        httpMethod = 'GET',
        path = '/',
        timestamp = Date.now(),
        apiClientKey = '',
        payload = null,
    }: AuthorizationInput) => {
        const reverse = (input: string) => Array.from(input).reverse().join('');
        const message = `${origin}${httpMethod}${path}${timestamp}${reverse(
            apiClientKey
        )}${payload ? JSON.stringify(payload) : ''}`;
        return HmacSHA256(message, apiClientKey).toString();
    };
}
