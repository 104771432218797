import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import OverviewCard, {
    OverviewFooterLink,
    OverviewTitleCard,
} from '../common/OverviewCard';
import mock from '../../mock/mocks';
import { getWidgetStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';

const RecommendationWidget = () => {
    const content = useContent();

    const { title: titleStyles, content: contentStyles } = getWidgetStyles('Ads');
    const { image, title, content: contentAds } = mock.data.ads;

    return (
        <OverviewCard
            id={'recommendation'}
            isHighlighted={true}
            title={
                <OverviewTitleCard titleText={content('widgets.recommendation.title')} />
            }
            footer={
                <Box textAlign={'center'} w={'100%'}>
                    <OverviewFooterLink onClick={() => {}}>
                        <>Find out more</>
                    </OverviewFooterLink>
                </Box>
            }
        >
            <Flex direction={'column'} gap={2}>
                <Box>
                    <Image src={image} alt={'recommended for you'} />
                </Box>
                <Box>
                    <Text {...titleStyles}>{title}</Text>
                </Box>
                <Box>
                    <Text {...contentStyles}>{contentAds}</Text>
                </Box>
            </Flex>
        </OverviewCard>
    );
};

export default RecommendationWidget;
