import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BreachDetailsTab from '../components/breach/BreachDetailsTab';
import BreachWelcomeBoard from '../components/breach/BreachWelcomeBoard';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { AppRoutes } from '../config/config';
import {
    IAccountEmail,
    IAssetTypeItem,
    IAssetTypeResponse,
    IBreadcrumbItem,
    NullableVar,
} from '../config/interface';
import { getPageStyles } from '../config/theme';
import { account } from '../entities/session';
import { fetchAssetByType } from '../services/apiService';
import { formatAssetName, getResponseError } from '../utils/helpers';

import addressIcon from '../assets/images/address.png';
import bankaccountIcon from '../assets/images/bank_account.png';
import creditcardIcon from '../assets/images/credit_card.png';
import driverslicenseIcon from '../assets/images/drivers_license.png';
import emailIcon from '../assets/images/email.png';
import fullnameIcon from '../assets/images/fullname.png';
import passportIcon from '../assets/images/passport.png';
import passwordIcon from '../assets/images/password.png';
import phoneIcon from '../assets/images/phone.png';
import usernameIcon from '../assets/images/username.png';
import useLogout from '../hooks/useLogout';

const BreachDetailsPage = () => {
    const doLogout = useLogout();
    const { main } = getPageStyles('BreachDetailsPage');
    const { id: email, asset } = useParams<{ id: string; asset: string }>();
    const { primaryEmail, secondaryEmails } = account.use();

    const pageIcon = {
        email: emailIcon,
        username: usernameIcon,
        password: passwordIcon,
        passport: passportIcon,
        address: addressIcon,
        bank_account: bankaccountIcon,
        credit_card: creditcardIcon,
        drivers_license: driverslicenseIcon,
        fullname: fullnameIcon,
        phone: phoneIcon,
    };

    const [selectedEmail, setSelectedEmail] = useState<IAccountEmail>({
        email: '',
        lastScanDate: 0,
        resolved: 0,
        exposed: 0,
        tag: '',
    });

    const [exposedItems, setExposedItems] = useState<NullableVar<IAssetTypeItem[]>>(null);
    const [resolvedItems, setResolvedItems] =
        useState<NullableVar<IAssetTypeItem[]>>(null);

    const items: IBreadcrumbItem[] = [
        { label: 'Dashboard', href: AppRoutes.DASHBOARD, current: false },
        { label: 'Data Breach Monitoring', href: AppRoutes.BREACH, current: false },
        {
            label: formatAssetName(asset || '') || '',
            href: AppRoutes.BREACH,
            current: true,
            content: (
                <Text textTransform={'capitalize'}>{formatAssetName(asset || '')}</Text>
            ),
        },
    ];

    const { isFetching: assetTypeFetching, refetch: refetchAssetType } =
        useQuery<IAssetTypeResponse>(
            ['asset', email, asset],
            () => fetchAssetByType({ email: selectedEmail.email, asset: asset || '' }),
            {
                enabled:
                    selectedEmail && selectedEmail.email && email && asset ? true : false,
                onError: assetTypeError => {
                    if (!assetTypeFetching && assetTypeError) {
                        const { status } = getResponseError(assetTypeError);
                        if (status === 401) {
                            doLogout();
                        }
                    }
                },
                onSuccess: assetTypeData => {
                    if (assetTypeData) {
                        const { exposed, resolved } = assetTypeData;
                        setExposedItems(exposed);
                        setResolvedItems(resolved);
                    }
                },
            }
        );

    useEffect(() => {
        if (email && !selectedEmail.email) {
            if (primaryEmail.email === email) {
                setSelectedEmail(primaryEmail);
            } else {
                if (secondaryEmails && secondaryEmails.length > 0) {
                    for (let x = 0; x < secondaryEmails?.length; x++) {
                        const secondary = secondaryEmails[x];

                        if (secondary.email === email) {
                            setSelectedEmail(secondary);
                        }
                    }
                }
            }
        }
    }, [email, primaryEmail, secondaryEmails, selectedEmail]);

    return (
        <Box id={'breachpage-details-page'} {...main}>
            <Flex direction={'column'} gap={0}>
                {/* MOBILE */}
                <Box
                    display={{ base: 'display', md: 'none' }}
                    backgroundColor={'white'}
                    p={'14px 0 0 14px'}
                >
                    <Breadcrumbs items={items} currentOnly={true} />
                </Box>

                {/* DESKTOP */}
                <Box
                    display={{ base: 'none', md: 'flex' }}
                    backgroundColor={'#F0F0F5'}
                    p={'24px 0 0 24px'}
                >
                    <Breadcrumbs items={items} />
                </Box>

                {asset && selectedEmail && selectedEmail.email && (
                    <BreachWelcomeBoard
                        title={
                            <Text textTransform={'capitalize'}>
                                {formatAssetName(asset || '')}
                            </Text>
                        }
                        email={selectedEmail}
                        selectable={false}
                        rescannable={false}
                        pageIcon={
                            <Image
                                src={pageIcon[asset || '']}
                                alt={`asset icon for ${asset}`}
                                width={'34px'}
                            />
                        }
                        rootStyle={{
                            height: { base: '225px', md: '212px' },
                        }}
                    />
                )}

                {selectedEmail && selectedEmail.email && (
                    <BreachDetailsTab
                        exposedItems={exposedItems}
                        resolvedItems={resolvedItems}
                        isLoading={assetTypeFetching}
                        asset={asset}
                        email={email}
                        onResolved={() => {
                            refetchAssetType();
                        }}
                    />
                )}
            </Flex>
        </Box>
    );
};

export default BreachDetailsPage;
