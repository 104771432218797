import {
    Stack,
    Flex,
    Container,
    Heading,
    Image,
    SimpleGrid,
    Center,
} from '@chakra-ui/react';
import { BannerTemplateProps } from '../../config/interface';
import { articleHeadingNodeOptions } from '../../utils/contentfulRenderOptions';
import renderDocument from '../../utils/renderDocument';

const ArticleBanner: React.FC<BannerTemplateProps> = ({
    bannerImage,
    bannerBackgroundColor,
    bannerFontColor,
    bannerHeading,
    bannerSubtext,
}) => {
    return (
        <Center w="full" minH="calc(100vh - 80px)" bg={bannerBackgroundColor}>
            <Container
                maxW="container.xl"
                color={bannerFontColor}
                py={20}
                px={10}
                centerContent
            >
                <SimpleGrid w="full" columns={{ base: 1, lg: 2 }} gap={6}>
                    <Flex align="center">
                        <Stack>
                            <Heading
                                fontSize={{ base: '5xl', md: '7xl' }}
                                fontWeight="bold"
                                flexGrow={1}
                            >
                                {bannerHeading}
                            </Heading>
                            {renderDocument(bannerSubtext, {
                                renderNode: articleHeadingNodeOptions,
                            })}
                        </Stack>
                    </Flex>
                    <Flex align="center" w="full">
                        <Image src={bannerImage.fields.file.url} />
                    </Flex>
                </SimpleGrid>
            </Container>
        </Center>
    );
};

export default ArticleBanner;
