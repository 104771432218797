import { Button, Divider, Flex, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { AssessmentQuestion } from '../../../config/interface';
import { WizardCardBottom } from "../../common/Wizard/WizardCardBottom";
import {
    WizardCardTop,
    WizardCardTopHeading
} from "../../common/Wizard/WizardCardTop";
import { WizardCard } from "../../common/Wizard/WizardCard";
import Logo from '../../../assets/images/SecurityAssessmentLogo.png';

interface Props {
    questions: AssessmentQuestion[];
    answers: string[];
    onEdit: (step: number) => void;
    onSubmit: () => void;
    isLoading?: boolean;
}

const TestReviewWizardCard: React.FC<Partial<Props>> = ({
    questions,
    answers,
    onEdit,
    onSubmit,
    isLoading,
}) => (
    <WizardCard>
        <WizardCardTop align="center" textAlign="center">
            <Image src={Logo} />
            <WizardCardTopHeading>Let's review your answers</WizardCardTopHeading>
        </WizardCardTop>
        <WizardCardBottom>
            <Stack divider={<Divider borderStyle="dashed" />}>
                {questions?.map(({ question }, i) => (
                    <Flex flexDir="column" my={3} key={question}>
                        <Text fontSize="lg" fontWeight={700}>
                            {question}
                        </Text>
                        <Flex align="center" justify="space-between" mt={4}>
                            <Text
                                fontSize="2xl"
                                fontWeight={700}
                                color="brand.primary.600"
                            >
                                {answers?.[i]}
                            </Text>
                            <Button
                                variant="ghost"
                                textDecor="underline"
                                w="min-content"
                                onClick={() => {
                                    if (onEdit) {
                                        onEdit(i + 1);
                                    }
                                }}
                            >
                                edit
                            </Button>
                        </Flex>
                    </Flex>
                ))}
                <Button
                    mt={4}
                    isLoading={isLoading}
                    onClick={() => {
                        if (onSubmit) {
                            onSubmit();
                        }
                    }}
                >
                    Confirm
                </Button>
            </Stack>
        </WizardCardBottom>
    </WizardCard>
);
export default TestReviewWizardCard;
