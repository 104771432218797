import { entity, persistence } from 'simpler-state';
import { AppConfig } from '../config/config';
import { NullableVar } from '../config/interface';

// SIMPLER STATE - https://simpler-state.js.org/

const accountKey = `${AppConfig.storagePrefix}_account`;
const tokenKey = `${AppConfig.storagePrefix}_token`;
const loginEmailKey = `${AppConfig.storagePrefix}_loginemail`;
const registerEmailKey = `${AppConfig.storagePrefix}_registeremail`;
const registerMdnKey = `${AppConfig.storagePrefix}_registermdn`;
const postLoginRedirectKey = `${AppConfig.storagePrefix}_redirect`;

export const account = entity<any>(null, [
    persistence(accountKey, {
        storage: 'session',
    }),
]);

export const authToken = entity<NullableVar<string>>(null, [
    persistence(tokenKey, {
        storage: 'session',
    }),
]);

export const registerEmail = entity<NullableVar<string>>(null, [
    persistence(registerEmailKey, {
        storage: 'local',
    }),
]);

export const registerMdn = entity<NullableVar<string>>(null, [
    persistence(registerMdnKey, {
        storage: 'local',
    }),
]);

export const loginEmail = entity<NullableVar<string>>(null, [
    persistence(loginEmailKey, {
        storage: 'local',
    }),
]);

export const postLoginRedirect = entity<NullableVar<string>>(null, [
    persistence(postLoginRedirectKey, {
        storage: 'local',
    }),
]);

export const getAuthToken = () => {
    return sessionStorage && sessionStorage[tokenKey] ? sessionStorage[tokenKey] : null;
};
