import React from 'react';
import { Box, Divider, Flex, HStack, Spacer } from '@chakra-ui/react';

import HeaderMenu from './HeaderMenu';
import { getComponentStyles } from '../../config/theme';
import HeaderDesktopLogo1Svg from '../../assets/HeaderDesktopLogo1Svg';
import HeaderDesktopLogoIcon from '../../assets/HeaderDesktopLogoIcon';
import HeaderMobileLogoIcon from '../../assets/HeaderMobileLogoIcon';

const Header: React.FC<{ authorized: boolean; onLogout?: Function }> = ({
    authorized,
    onLogout,
}) => {
    const { main, divider } = getComponentStyles('Header');

    return (
        <Box id={'header'} {...main}>
            <HStack>
                <Flex direction={'row'} gap={2} display={{ base: 'none', md: 'flex' }}>
                    <HeaderDesktopLogoIcon mt={0} mr={'10px'} />
                    <Divider {...divider} />
                    <HeaderDesktopLogo1Svg />
                </Flex>

                <Box display={{ base: 'flex', md: 'none' }} width={'300px'}>
                    <HeaderMobileLogoIcon mr={'10px'} mt={0} />
                    <Divider {...divider} />
                    <HeaderDesktopLogo1Svg />
                </Box>

                <Spacer />
                {authorized && <HeaderMenu onLogout={onLogout} />}
            </HStack>
        </Box>
    );
};

export default Header;
