import React, { useEffect, useState } from 'react';
import { Box, Flex, HStack, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import NewArticlesSvg from '../../assets/NewArticlesSvg';
import { getWidgetStyles } from '../../config/theme';
import BorderedBox from '../layouts/BorderedBox';
import VerticalCenter from '../layouts/VerticalCenter';
import { ILatestArticleResponse, NullableVar } from '../../config/interface';
import useContent from '../../hooks/useContent';

const NewArticlesWidget = ({ items, isLoading }) => {
    const content = useContent();
    const { title, subtitle2, subtitle, newArticles, newArticlesBox, newArticleItemBox } =
        getWidgetStyles('Discover');

    const [latestArticles, setLatestArticles] =
        useState<NullableVar<ILatestArticleResponse[]>>(null);

    const [showMore, setShowMore] = useState<NullableVar<boolean>>();

    useEffect(() => {
        if (items) {
            setLatestArticles(items);

            if (items && items.length > 3) {
                setShowMore(true);
            } else {
                setShowMore(false);
            }
        }
    }, [items]);

    return (
        <Box id={'dashboard-new-articles-widget'} {...newArticles}>
            <Flex direction={'column'} gap={1}>
                <HStack>
                    <NewArticlesSvg />
                    <Text {...title}>{content('widgets.articles.latesthappenings')}</Text>
                </HStack>
                <BorderedBox
                    styleProps={newArticlesBox}
                    onScroll={target => {
                        const top = target.scrollTop;

                        if (top > 30) {
                            setShowMore(false);
                        } else {
                            setShowMore(true);
                        }
                    }}
                >
                    {isLoading && (
                        <VerticalCenter
                            innerProps={{
                                w: 'auto',
                                m: '0',
                                borderRadius: '8px',
                            }}
                            h={'100%'}
                        >
                            <Spinner variant={'componentLoader'} />
                        </VerticalCenter>
                    )}
                    {!isLoading && (
                        <VStack minH={'315px'} position={'relative'}>
                            {latestArticles?.map((item, index) => {
                                const { title: articleTitle, subTitle, url, date } = item;

                                return (
                                    <Link
                                        key={`latest-articles-${index}`}
                                        href={url}
                                        textDecoration={'none'}
                                        target={'_blank'}
                                    >
                                        <Box
                                            key={`latest-${index}`}
                                            {...newArticleItemBox}
                                        >
                                            <Text {...title}>{articleTitle}</Text>
                                            <Text {...subtitle}>{subTitle}</Text>
                                            <Text {...subtitle2}>{date}</Text>
                                        </Box>
                                    </Link>
                                );
                            })}
                            {showMore && (
                                <Box
                                    w={'150px'}
                                    p={'4px'}
                                    pos={'sticky'}
                                    bottom={'0px'}
                                    zIndex={'999'}
                                    backgroundColor={'white'}
                                    opacity={'0.8'}
                                    borderColor={'brand.primary.500'}
                                    borderWidth={'1px'}
                                    borderStyle={'solid'}
                                    borderRadius={'8px'}
                                    color={'brand.primary.500'}
                                    textAlign={'center'}
                                    fontSize={'14px'}
                                >
                                    scroll to see more
                                </Box>
                            )}
                        </VStack>
                    )}
                </BorderedBox>
            </Flex>
        </Box>
    );
};

export default NewArticlesWidget;
