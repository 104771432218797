import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../components/header/Header';
import FullWidth from '../components/layouts/FullWidth';
import { NullableVar } from '../config/interface';
import { AppRoutes } from '../config/routes';
import { registerMdn } from '../entities/session';
import useContent from '../hooks/useContent';
import useErrorToast from '../hooks/useErrorToast';
import { verifyRegisteredMobileNumber } from '../services/apiService';

const VerifyMobilePage = () => {
    const content = useContent();
    const errToast = useErrorToast();
    const [mdn, setMdn] = useState<NullableVar<string>>('');
    const [verified, setVerified] = useState(false);

    const { mutate: verifyMdn, isLoading: verifyingMdn } = useMutation({
        mutationFn: verifyRegisteredMobileNumber,
        onSuccess: (data: any) => {
            if (data?.sent || data?.success) {
                registerMdn.set(mdn || '');
                setVerified(true);
            }
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
            setVerified(false);
        },
    });

    const onSubmit = e => {
        e.preventDefault();
        verifyMdn({ mdn: mdn || '' });
    };

    return (
        <FullWidth id={'verify-mobile-page'}>
            {verified === true && <Navigate to={AppRoutes.VERIFY_MOBILE_OTP} />}
            <Header authorized={false}></Header>

            <Box marginTop={{ base: '84px', md: '112px' }}>
                <Card w={{ base: '343px', md: '464px' }} m={'0 auto'}>
                    <CardHeader bgGradient="linear(91.62deg, grayscale.400, grayscale.600)">
                        <VStack alignItems={'baseline'} spacing={2}>
                            <Text fontSize={24} fontWeight={700} marginRight={'7px'}>
                                {content('forms.registration.verify.welcome.header')}
                            </Text>

                            <Text fontSize="1em">
                                {content('forms.registration.verify.welcome.content')}
                            </Text>
                        </VStack>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={onSubmit}>
                            <FormControl>
                                <Stack spacing={2}>
                                    <FormLabel fontWeight={'700'}>
                                        {content(
                                            'forms.registration.verify.welcome.fields.mobile.label'
                                        )}
                                    </FormLabel>
                                    <Input
                                        type={'number'}
                                        value={mdn || ''}
                                        onChange={e => {
                                            setMdn(e.target.value);
                                        }}
                                        placeholder={content(
                                            'forms.registration.verify.welcome.fields.mobile.placeholder'
                                        )}
                                    />
                                    <Button
                                        type="submit"
                                        onClick={onSubmit}
                                        isLoading={verifyingMdn}
                                        disabled={verifyingMdn}
                                    >
                                        {!verifyingMdn
                                            ? content(
                                                  'forms.registration.verify.welcome.fields.submit.label'
                                              )
                                            : 'Verifying'}
                                    </Button>
                                </Stack>
                            </FormControl>
                        </form>
                    </CardBody>
                </Card>
            </Box>
        </FullWidth>
    );
};

export default VerifyMobilePage;
