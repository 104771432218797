import {
    HStack,
    VStack,
    Card,
    CardHeader,
    CardBody,
    Box,
    Divider,
    Input,
    InputGroup,
    InputRightAddon,
    Image,
    Heading,
    Spacer,
    CloseButton,
    CardFooter,
    Flex,
    useBoolean,
} from '@chakra-ui/react';
import * as React from 'react';
import AttachFileIconSvg from './assets/AttachFileIconSvg';
import CloseIconSvg from './assets/CloseIconSvg';
import MinimizeIconSvg from './assets/MinimizeIconSvg';
import SendIconSvg from './assets/SendIconSvg';
import ExpertImage from './assets/images/experts.png';
import { Field, Formik } from 'formik';
import { getComponentStyles } from '../config/theme';
import HeaderMobileLogoIcon from '../assets/HeaderMobileLogoIcon';
import SecurityAdvisorLogoOnlyIcon from '../assets/SecurityAdvisorLogoOnlyIcon';
import ChatIconSvg from './assets/ChatIconSvg';
import { postLoginRedirect } from '../entities/session';
import { AppComponents, LoginRedirects } from '../config/config';
import {
    ChatMessage,
    ChatWidgetProps,
    FetchStatus,
    Message,
    MessageTypes,
} from './types';
import MessageComponent from './components/Message';
import { ChatManager } from './ChatManager';
import { configure } from './services/ChatService';
import { noop } from '../utils/helpers';
import {
    chatConversationIdEntity,
    chatRequestIdEntity,
    chatVisitorIdEntity,
    getChatSession,
    hasActiveChatEntity,
} from './entities/ChatEntities';
import { Nullable } from '../utils/types';

const { useState, useEffect, useCallback, useMemo } = React;

export const ChatWidget: React.FC<ChatWidgetProps> = ({
    icons = {
        chat: ChatIconSvg,
        close: CloseIconSvg,
        minimize: MinimizeIconSvg,
    },
    open,
    appSyncConfig,
    onEnd = noop,
}) => {
    const ChatIcon = icons.chat;
    const CloseIcon = icons.close;
    const MinimizeIcon = icons.minimize;

    const { divider } = getComponentStyles('Header');
    const redirectTo = postLoginRedirect.use();

    const chatRequestId = chatRequestIdEntity.use();
    const chatConversationId = chatConversationIdEntity.use();
    const chatVisitorId = chatVisitorIdEntity.use();
    const hasActiveChat = hasActiveChatEntity.use();

    const [isOpen, setIsOpen] = useBoolean(open);
    const [requestId, setRequestId] = useState<Nullable<string>>(null);
    const [conversationId, setConversationId] = useState<Nullable<string>>(null);
    const [visitorId, setVisitorId] = useState<Nullable<string>>(null);
    const [activeChat, setActiveChat] = useBoolean();

    const [chat, setChat] = useState<Array<Message>>([]);
    const [messages, setMessages] = useState<Array<ChatMessage>>([]);
    const [isChatConnected, setIsChatConected] = useBoolean();
    const [isNoAvailableAgents, setIsNoAvailableAgents] = useBoolean();
    const [fetchChatRequestIdStatus, setFetchChatRequestIdStatus] = useState<FetchStatus>(
        FetchStatus.INIT
    );

    const submitHandler = ({ message }, { resetForm }) => {
        chatManager.sendMessage(message);
    };

    configure(appSyncConfig);

    let chatManager = useMemo(
        () =>
            new ChatManager(
                appSyncConfig.clientName,
                'DEV',
                {
                    engagementApiUrl: new URL(
                        `${process.env.REACT_APP_ENGAGEMENT_API_WRAPPER_URL}${process.env.REACT_APP_ENGAGEMENT_API_WRAPPER_PATH}`
                    ),
                    path: process.env.REACT_APP_ENGAGEMENT_API_WRAPPER_PATH ?? '',
                    program: process.env.REACT_APP_ENGAGEMENT_API_WRAPPER_PROGRAM ?? '',
                    channel: process.env!.REACT_APP_ENGAGEMENT_API_WRAPPER_CHANNEL ?? '',
                    engagementApiKey:
                        process.env.REACT_APP_ENGAGEMENT_API_ENGAGEMENT_API_KEY ?? '',
                },
                {
                    chatType: appSyncConfig.chatType,
                    skillId: appSyncConfig.skillId,
                    clientId: appSyncConfig.clientId,
                }
            ),
        [appSyncConfig]
    );

    useEffect(() => {
        if (chat.length === 0) {
            setChat([
                {
                    message: 'Hi, How can we help you today?',
                    source: 'system',
                },
            ]);
        }

        if (redirectTo) {
            const { component } = LoginRedirects[redirectTo.toUpperCase()];
            if (component === AppComponents.CHAT) {
                postLoginRedirect.set(null);
                setIsOpen.on();
            }
        }
    }, [isOpen, open, chat, redirectTo]);

    useEffect(() => {
        if (fetchChatRequestIdStatus === FetchStatus.INIT) {
            setRequestId(null);
            setConversationId(null);
            setVisitorId(null);
            setActiveChat.off();
            setFetchChatRequestIdStatus(FetchStatus.FETCHING);
            getChatSession(chatRequestId);
        }
    }, [
        hasActiveChat,
        activeChat,
        requestId,
        conversationId,
        fetchChatRequestIdStatus,
        chatRequestId,
        chatConversationId,
        chatVisitorId,
        chatManager,
    ]);

    const chatConnected = useCallback(setIsNoAvailableAgents.off, []);

    const messageReceived = useCallback(async () => {
        const incomingMessages = await chatManager.getAllMessages();
        setMessages(incomingMessages);
    }, [chatManager]);

    const chatDisconnected = useCallback(() => {
        terminateChatSession();
    }, []);

    const startChat = async () => {
        chatManager.onConnected = chatConnected;
        chatManager.onMessageReceived = messageReceived;
        chatManager.onDisconnected = chatDisconnected;
        // chatManager.onAgentTyping = agentTyping;

        console.log('activeChat', activeChat);
        await chatManager.create(activeChat, requestId, conversationId, visitorId);
        const availableAgents = await chatManager.hasAvailableAgents();
    };

    const terminateChatSession = useCallback(() => {
        if (fetchChatRequestIdStatus === FetchStatus.COMPLETE) {
            chatManager.end();
            setIsChatConected.off();
            setIsNoAvailableAgents.off();
            setMessages([]);
            setActiveChat.off();
            setFetchChatRequestIdStatus(FetchStatus.INIT);
            console.log('Chat is now terminated.');
            onEnd();
        }
    }, [fetchChatRequestIdStatus, chatManager, onEnd]);

    const triggerEndChat = useCallback(terminateChatSession, [terminateChatSession]);

    if (!isOpen) {
        return (
            <ChatIcon
                w={{ base: '48px', md: '60px' }}
                h={{ base: '48px', md: '60px' }}
                position={'fixed'}
                right={{ base: 4, md: 6 }}
                bottom={{ base: 4, md: 6 }}
                _hover={{ cursor: 'pointer' }}
                onClick={setIsOpen.on}
            />
        );
    }
    return (
        <VStack
            position={'fixed'}
            right={{ md: 6, base: 0 }}
            bottom={{ md: 6, base: 0 }}
            rounded={{ md: 4, base: 0 }}
            gap={2}
            h={{ md: 'calc(100vh - 100px)', base: '100vh' }}
            zIndex={9999}
            justifyContent={'flex-end'}
            w={{ md: 'auto', base: 'full' }}
        >
            <Flex
                justifyContent={'flex-end'}
                w={{ md: 'auto', base: 'full' }}
                flexDirection={'column'}
            >
                <Box display={{ md: 'none', base: 'flex' }} background={'black'} p={4}>
                    <HStack w={'full'}>
                        <Box display={{ base: 'flex', md: 'none' }} width={'200px'}>
                            <HeaderMobileLogoIcon mr={'10px'} mt={'4px'} />
                            <Divider {...divider} />
                            <SecurityAdvisorLogoOnlyIcon ml={'10px'} />
                        </Box>

                        <Spacer />
                        <CloseButton onClick={() => setIsOpen.off} color={'white'} />
                    </HStack>
                </Box>
                <Card
                    w={{ md: '344px', base: 'full' }}
                    maxH={{ md: 'calc(100vh - 100px)', base: 'calc(100vh - 64px)' }}
                    variant={{ md: 'outline', base: 'unstyled' }}
                    alignSelf={'flex-end'}
                    h={{ md: '644px', base: '100%' }}
                    borderRadius={{ md: '8px', base: 0 }}
                >
                    <CardHeader px={'17px'} pt={'17px'} pb={0} borderRadius={0}>
                        <HStack gap={2}>
                            <Image src={ExpertImage} />
                            <VStack alignItems={'flex-start'} justifyContent={'center'}>
                                <Heading fontSize={16} fontWeight={700} color="#3D3D3B">
                                    Your team of experts
                                </Heading>
                                <Heading
                                    fontSize={12}
                                    fontWeight={400}
                                    color="#3D3D3B"
                                    mt={'0 !important'}
                                >
                                    Ask us anything
                                </Heading>
                            </VStack>
                        </HStack>
                    </CardHeader>
                    <CardBody
                        display={'flex'}
                        justifyContent={'space-between'}
                        flexDirection={'column-reverse'}
                        gap={1}
                        py={'17px'}
                        pb={'4px'}
                        maxH={{ md: 'calc(100vh - 150px)', base: 'none' }}
                        height={{ md: 'calc(100vh - 150px)', base: '100%' }}
                        overflow={'auto'}
                    >
                        <VStack width={'full'}>
                            {chat.map(({ message, source }, i) => (
                                <MessageComponent
                                    key={`message-${i}`}
                                    type={source as MessageTypes}
                                >
                                    {message}
                                </MessageComponent>
                            ))}
                        </VStack>
                    </CardBody>
                    <Divider
                        color={'#E6E6EB'}
                        mx={'auto'}
                        mt={{ md: '19px', base: '8px' }}
                        mb={0}
                        width={{ md: 'calc(100% - 34px)', base: 'calc(100% - 16px)' }}
                    />
                    <CardFooter
                        px={{ md: '17px', base: '14px' }}
                        pt={{ md: '8px', base: '4px' }}
                        pb={'15px'}
                    >
                        <Formik
                            initialValues={{
                                message: '',
                            }}
                            onSubmit={submitHandler}
                        >
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Field name="message">
                                        {({ field, form }) => (
                                            <InputGroup w={'full'}>
                                                <Input
                                                    {...field}
                                                    px={'7px'}
                                                    py={'11px'}
                                                    border="none"
                                                    placeholder="Type a message"
                                                    _focusVisible={{ border: 'none' }}
                                                />
                                                <InputRightAddon
                                                    bg={'none'}
                                                    border={'none'}
                                                    px={0}
                                                    children={
                                                        <HStack>
                                                            <SendIconSvg
                                                                w={'24px'}
                                                                h={'24px'}
                                                                _hover={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() =>
                                                                    form.submitForm()
                                                                }
                                                            />
                                                            <AttachFileIconSvg
                                                                w={'24px'}
                                                                h={'24px'}
                                                                _hover={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </HStack>
                                                    }
                                                />
                                            </InputGroup>
                                        )}
                                    </Field>
                                </form>
                            )}
                        </Formik>
                    </CardFooter>
                </Card>
            </Flex>
            <HStack alignSelf={'flex-end'} display={{ md: 'flex', base: 'none' }}>
                <MinimizeIcon
                    w={{ md: '48px' }}
                    h={{ md: '48px' }}
                    _hover={{ cursor: 'pointer ' }}
                    onClick={setIsOpen.off}
                />
                <CloseIcon
                    w={{ md: '48px' }}
                    h={{ md: '48px' }}
                    _hover={{ cursor: 'pointer ' }}
                    onClick={setIsOpen.off}
                />
            </HStack>
        </VStack>
    );
};

export default ChatWidget;
