import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    HStack,
    Image,
    Spinner,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../components/common/Breadcrumbs';
import RouteLink from '../components/common/RouteLink';
import ArticleImagePlaceholder from '../components/discover/ArticleImagePlaceholder';
import ArticleTopicFilter from '../components/discover/ArticleTopicFilter';
import { AppRoutes, QueryKeys } from '../config/config';
import { IBreadcrumbItem } from '../config/interface';
import { getPageStyles } from '../config/theme';
import { securityArticles } from '../entities/articles';
import useContent from '../hooks/useContent';
import useLogout from '../hooks/useLogout';
import { fetchArticleTopics, fetchSecurityArticles } from '../services/apiService';
import { getResponseError } from '../utils/helpers';

const DiscoverSecurityPage = () => {
    const content = useContent();
    const doLogout = useLogout();
    const { main, top, title, subtitle1, filter, filterTitle, article } =
        getPageStyles('DiscoverSecurityPage');

    const [selectedTopic, setSelectedTopic] = useState<string>('all');

    const breadcrumbItems: IBreadcrumbItem[] = [
        { label: 'Dashboard', href: AppRoutes.DASHBOARD, current: false },
        { label: 'Discover Security', href: AppRoutes.DISCOVER, current: true },
    ];

    let {
        data: topics,
        error: topicError,
        isFetching: topicFetching,
    } = useQuery<string[]>(QueryKeys.articles.topics, () => fetchArticleTopics(), {
        onError: topicError => {
            const { status } = getResponseError(topicError);
            if (status === 401) {
                doLogout();
            }
        },
    });

    if (topics && !topicFetching && !topicError) {
        let newTopics = ['all'];
        for (let x = 0; x < topics.length; x++) {
            newTopics.push(topics[x]);
        }
        topics = newTopics;
    }

    const { data: articles } = useQuery<any>(
        QueryKeys.articles.list(selectedTopic),
        () => fetchSecurityArticles({ topic: selectedTopic }),
        {
            enabled: topics && topics.length > 0 && selectedTopic ? true : false,
            onError: articleError => {
                const { status } = getResponseError(articleError);
                if (status === 401) {
                    doLogout();
                }
            },
        }
    );

    useEffect(() => {
        if (articles && articles.length > 0 && selectedTopic === 'all') {
            let _articles = {};
            for (let x = 0; x < articles.length; x++) {
                const article = articles[x];
                const { slug } = article;
                _articles[slug] = article;
            }
            securityArticles.set(_articles);
        }
    }, [articles, selectedTopic]);

    return (
        <Box id={'discoverysecuritypage'} {...main}>
            <Flex direction={'column'} gap={0}>
                <Box backgroundColor={'white'} p={'14px 0 14px 14px'}>
                    <Breadcrumbs items={breadcrumbItems} currentOnly={false} />
                </Box>

                <Box {...top}>
                    <Text {...title}>{content('discover.title')}</Text>
                    <Text {...subtitle1}>{content('discover.subtitle')}</Text>
                </Box>
                <Box {...filter}>
                    {topics && topics.length > 0 && (
                        <HStack overflow={'auto'}>
                            <Box>
                                <Text {...filterTitle}>Topics</Text>
                            </Box>
                            <ArticleTopicFilter
                                topics={topics}
                                selectedTopic={selectedTopic}
                                onSelectTopic={
                                    !topicFetching
                                        ? topic => setSelectedTopic(topic)
                                        : () => {}
                                }
                            />
                        </HStack>
                    )}
                </Box>
                {topicFetching && (
                    <Box textAlign={'center'}>
                        <Spinner variant={'pageLoader'} mt={'100px'} />
                    </Box>
                )}
                {!topicFetching && (
                    <Box backgroundColor={'white'}>
                        <Wrap gap={2} p={6} pr={{ base: 4, md: 6 }}>
                            {articles &&
                                articles?.map((item, i) => {
                                    const { date, title, subTitle, thumbnail, slug } =
                                        item;
                                    return (
                                        <WrapItem key={`article-item-${i}`}>
                                            <Card
                                                as={RouteLink}
                                                to={AppRoutes.DISCOVER_ARTICLE.replaceAll(
                                                    ':slug',
                                                    slug
                                                )}
                                                variant={'outline'}
                                                boxShadow="none"
                                                _hover={{
                                                    boxShadow: 'lg',
                                                    transform: 'scale(1.02)',
                                                }}
                                                {...article.card}
                                                key={`article-item-${i}`}
                                            >
                                                <CardHeader padding={'10px 0'}>
                                                    {thumbnail && (
                                                        <Box
                                                            height={{
                                                                base: '110px',
                                                                md: '200px',
                                                            }}
                                                            pt={'10%'}
                                                        >
                                                            <Image
                                                                {...article.image}
                                                                src={thumbnail?.file?.url}
                                                                alt={
                                                                    thumbnail?.title ||
                                                                    title
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                    {!thumbnail && (
                                                        <ArticleImagePlaceholder
                                                            width={{
                                                                base: '164px',
                                                                md: '300px',
                                                            }}
                                                            height={{
                                                                base: '110px',
                                                                md: '200px',
                                                            }}
                                                            style={article.image}
                                                        />
                                                    )}
                                                </CardHeader>
                                                <CardBody mt={5} pb={2} px={4}>
                                                    <VStack alignItems={'baseline'}>
                                                        <Text {...article.title}>
                                                            {title}
                                                        </Text>
                                                        <Text {...article.subtitle}>
                                                            {subTitle}
                                                        </Text>
                                                    </VStack>
                                                </CardBody>
                                                <CardFooter>
                                                    <Text {...article.date}>{date}</Text>
                                                </CardFooter>
                                            </Card>
                                        </WrapItem>
                                    );
                                })}
                        </Wrap>
                    </Box>
                )}
            </Flex>
        </Box>
    );
};

export default DiscoverSecurityPage;
