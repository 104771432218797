import { Container, SimpleGrid, Image, Flex, Box } from '@chakra-ui/react';
import { TwoColumnBlockProps } from '../../config/interface';
import { articleHeadingNodeOptions } from '../../utils/contentfulRenderOptions';
import renderDocument from '../../utils/renderDocument';

const ArticleTwoColumnBlock: React.FC<TwoColumnBlockProps> = ({
    data,
    image,
    imagePosition,
    backgroundColor,
    fontColor,
}) => {
    const isImgLeft = imagePosition.toLowerCase() === 'left';

    return (
        <Box bg={backgroundColor}>
            <Container maxW={'5xl'} py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    {isImgLeft && <Image src={image.fields.file.url} />}
                    <Flex color={fontColor} justify="center" flexDir="column">
                        {renderDocument(data, {
                            renderNode: articleHeadingNodeOptions,
                        })}
                    </Flex>
                    {!isImgLeft && <Image src={image.fields.file.url} />}
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default ArticleTwoColumnBlock;
