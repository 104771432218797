import {
    Box,
    Button,
    Flex,
    HStack,
    Image,
    Spacer,
    Spinner,
    Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ArrowGoToLinkSvg from '../../assets/ArrowGoToLinkSvg';
import DiscoverArticlesSvg from '../../assets/DiscoverArticlesSvg';
import { AppRoutes } from '../../config/config';
import { IArticleResponse, NullableVar } from '../../config/interface';
import { getPageStyles, getWidgetStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';
import { createUrl } from '../../utils/helpers';
import RouteLink from '../common/RouteLink';
import ArticleImagePlaceholder from '../discover/ArticleImagePlaceholder';
import BorderedBox from '../layouts/BorderedBox';

const DiscoverArticlesWidget = ({ items, isLoading }) => {
    const content = useContent();
    const { title, subtitle, subtitle1, discoverArticlesBox, discoverArticleItemBox } =
        getWidgetStyles('Discover');
    const { article } = getPageStyles('DiscoverSecurityPage');
    const [articles, setArticles] = useState<NullableVar<IArticleResponse[]>>();

    useEffect(() => {
        if (items) {
            setArticles(items);
        }
    }, [items]);

    return (
        <Box id={'dashboard-discover-articles-widget'} {...discoverArticlesBox}>
            <Flex direction={'column'}>
                <Flex direction={'row'} mb={'5px'}>
                    <HStack>
                        <DiscoverArticlesSvg />

                        <Text {...title}>{content('widgets.articles.discover')}</Text>

                        {isLoading && (
                            <Spinner
                                variant={'componentLoader'}
                                width={'16px'}
                                height={'16px'}
                            />
                        )}
                    </HStack>

                    <Spacer />
                    <Box display={{ base: 'none', md: 'flex' }}>
                        <RouteLink
                            to={AppRoutes.DISCOVER}
                            fontSize={'16px'}
                            fontWeight={700}
                        >
                            <HStack>
                                <Text>{content('widgets.articles.seemore')}</Text>
                                <ArrowGoToLinkSvg />
                            </HStack>
                        </RouteLink>
                    </Box>
                </Flex>
                <Box>
                    <Flex
                        className="discover-articles-list"
                        direction={{ base: 'column', md: 'row' }}
                        gap={{ base: 2, md: 0 }}
                    >
                        {!isLoading &&
                            articles?.map((item, index) => {
                                const { title, subTitle, slug, thumbnail } = item;

                                const styles =
                                    index === 0
                                        ? { ...discoverArticleItemBox, marginLeft: 0 }
                                        : discoverArticleItemBox;

                                return (
                                    <BorderedBox
                                        key={`articles-${index}`}
                                        styleProps={styles}
                                    >
                                        <Box textAlign={'center'} pt={'10px'}>
                                            {thumbnail && (
                                                <Image
                                                    src={thumbnail?.file?.url}
                                                    alt={thumbnail?.title}
                                                    m={'0 auto'}
                                                />
                                            )}
                                            {!thumbnail && (
                                                <ArticleImagePlaceholder
                                                    width={{
                                                        base: '150px',
                                                        md: '150px',
                                                    }}
                                                    height={{
                                                        base: '150px',
                                                        md: '150px',
                                                    }}
                                                    style={article.image}
                                                />
                                            )}
                                        </Box>

                                        <Box p={'16px'} position={'relative'} h={'170px'}>
                                            <Flex direction={'column'} gap={0}>
                                                <Box mb={'16px'}>
                                                    <RouteLink
                                                        to={AppRoutes.DISCOVER_ARTICLE.replaceAll(
                                                            ':slug',
                                                            slug || ''
                                                        )}
                                                    >
                                                        <Text
                                                            {...subtitle}
                                                            color={'brand.primary.500'}
                                                        >
                                                            {title}
                                                        </Text>
                                                    </RouteLink>
                                                </Box>
                                                <Box>
                                                    <Text {...subtitle1}>{subTitle}</Text>
                                                </Box>
                                            </Flex>
                                            <Box position={'absolute'} bottom={'0'}>
                                                {/* <Text {...subtitle2}>
                                                    Date 10 mins read
                                                </Text> */}
                                            </Box>
                                        </Box>
                                    </BorderedBox>
                                );
                            })}
                        <Button
                            variant={'outline'}
                            display={{ base: 'flex', md: 'none' }}
                            w={'162px'}
                            m={'0 auto'}
                            fontSize={'16px'}
                            color={'#111111'}
                            onClick={() => {
                                window.location.href = createUrl(AppRoutes.DISCOVER);
                            }}
                        >
                            Load More Articles
                        </Button>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default DiscoverArticlesWidget;
