import { ReactNode } from 'react';
import { Asset, Entry } from 'contentful';
import { Document } from '@contentful/rich-text-types';
import { RiskLevel } from './config';

export type NullableObj<T> = {
    [P in keyof T]: T[P] | null;
};

export type NullableVar<T> = T | null;

export interface IAccountEmail {
    email: string;
    createdDate?: number;
    lastScanDate?: number;
    exposed?: number;
    resolved?: number;
    tag?: string;
    monthlyScanSubscription?: boolean;
}
export interface IAccountDetails {
    firstName: string;
    lastName: string;
    primaryEmail: IAccountEmail;
    secondaryEmails: IAccountEmail[];
    familyMembers: string[];
    isPrimary: boolean;
}
export interface ISecurityAssessmentRecommendation {
    title: string;
    content: Document;
}
export interface ISecurityAssessmentMonthlyData {
    month: string;
    score: number;
    risk: string;
}
export interface ISecurityAssessmentSummary {
    allowReTake: boolean;
    monthly: ISecurityAssessmentMonthlyData[];
    recommendation: ISecurityAssessmentRecommendation;
    risk: string;
    score: number;
}

export interface IBreachDetails {
    id: string;
}

export interface IAssetItem {
    name: string;
    exposed: number;
    resolved: number;
}

export interface IAssetTypeItem {
    accountsAffected?: number;
    dateReported?: string;
    details?: string;
    iconUrl?: string;
    maskedItem?: string;
    site?: string;
    sourceId?: number;
    title?: string;
}

export interface IAssets {
    resolved: NullableVar<number>;
    exposed: NullableVar<number>;
    lastScanDate: NullableVar<number>;
    assets: NullableObj<Record<string, NullableObj<IAssetItem>>>;
    secondaryEmail?: any[];
}

export interface IOTPResponse {
    token: string;
}

export interface IAccountDetailsResponse {
    user: IAccountDetails;
}

export interface IBreadcrumbItem {
    label: string;
    href: string;
    current: boolean;
    content?: ReactNode;
}

export interface IAssetTypeResponse {
    exposed: IAssetTypeItem[];
    resolved: IAssetTypeItem[];
}

export interface IAssetTypeUnmaskedResponse {
    unmaskedItem: string;
}

export interface IMarkedResolveResponse {
    message: string;
}

export interface BranchResolutionSteps {
    icon?: Asset;
    steps?: Document;
    message?: string;
}

export interface IArticleThumbnailFileResponse {
    details?: any;
    fileName?: string;
    url?: string;
    contentType?: string;
}

export interface IArticleThumbnailResponse {
    file?: IArticleThumbnailFileResponse;
    title?: string;
}

// For "sa2ArticleBannerBlock"
export interface BannerTemplateProps {
    bannerBackgroundColor: string;
    bannerFontColor: string;
    bannerHeading: string;
    bannerImage: Asset;
    bannerImagePosition: string;
    bannerSubtext: Document;
}

// For "sa2ArticleVideoOrDescriptionBlock"
export interface VideoOrDescriptionBlockProps {
    video?: Asset;
    description?: Document;
    backgroundColor?: string;
    fontColor?: string;
    fontSize?: string;
}

export interface TwoColumnBlockProps {
    data: Document;
    image: Asset;
    imagePosition: string;
    backgroundColor: string;
    fontColor: string;
}

export interface BaseArticleTemplate<BlockType extends string, FieldsType = unknown> {
    blockType: BlockType;
    fields: FieldsType;
}

export enum BlockType {
    Banner = 'sa2ArticleBannerBlock',
    VideoOrDescription = 'sa2ArticleVideoOrDescriptionBlock',
    TwoColumn = 'sa2ArticleTwoColumnBlock',
}

export type ArticleTemplate =
    | BaseArticleTemplate<BlockType.Banner, BannerTemplateProps>
    | BaseArticleTemplate<BlockType.VideoOrDescription, VideoOrDescriptionBlockProps>
    | BaseArticleTemplate<BlockType.TwoColumn, TwoColumnBlockProps>;

export interface IArticleResponse {
    buttonText?: string;
    category?: string[];
    subTitle?: string;
    tags?: string[];
    thumbnail?: IArticleThumbnailResponse;
    title?: string;
    url?: string;
    slug?: string;
    articleTemplate?: Entry<unknown>[];
}

export interface ILatestArticleResponse {
    category?: string[];
    slug?: string;
    subTitle?: string;
    title?: string;
    url?: string;
    date?: string;
}

export interface IDashboardArticleResponse {
    discoverSecurity: IArticleResponse[];
    latestHappenings: ILatestArticleResponse[];
}

// Assessment Pages Typings

export interface AssessmentAsset {
    category: string;
    guide: string;
}

export interface AssessmentResult {
    assets: {
        securedAssets: AssessmentAsset[];
        compromisedAssets: (AssessmentAsset & { resolved: boolean })[];
    };
    score: number;
    risk: RiskLevel;
    recommendation?: {
        title: string;
        category: string;
        content: Document;
        securityTip: Document;
    };
    allowRetake: boolean;
}

export interface GuideStep {
    stepTitle: string;
    stepDescriptionTitle?: string;
    stepDescription: Document;
    proTipDescription?: Document;
    buttonText?: string;
}
export interface AssessmentGuide {
    title: string;
    guideDescription: Document;
    description: string;
    guideType: string;
    guideOverviewTitle: string;
    guideOverviewDescription: Document;
    guideSteps: GuideStep[];
}

export interface AssessmentQuestion {
    question: string;
    options: string[];
    category: string;
}
